import './CombatUi.css';
import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { useStores } from '../../hooks/useStore';
import { coinImages, PriceEnum } from '../../types/shop';
import { getCurrentFormula, getFormula } from '../../utils/formulas';
import { Currency } from '../Currency';
import { Footer } from '../main/Footer';
import { ReviveUi } from './ReviveUi';
import { Subscriptions } from './Subscriptions';

interface IProps {
  round: number;
  setRound: (round: number) => void;
  amountOfDamage?: string;
  playerHp: number;
  enemyHp: number;
  isRevive: boolean;
  isGameOver: boolean;
  reward?: number;
  playDeckReward?: number;
  isPreGameOpen: boolean;
  isBattleInitialized: boolean;
  isPlayerStep: boolean;
  onStartBattleClick: () => void;
  tapsCount: number;
  onSetTimeScale: (scale: 1 | 0) => void;
  onContinueBattle: () => void;
  onGameOver: () => void;
  softProfit: number;
  playDeckProfit: number;
  isShowCombatButton: boolean;
}

export const CombatUi = observer((props: IProps) => {
  const {
    round,
    isShowCombatButton,
    amountOfDamage,
    reward,
    playerHp,
    enemyHp,
    isRevive,
    isGameOver,
    isPreGameOpen,
    isBattleInitialized,
    isPlayerStep,
    onStartBattleClick,
    tapsCount,
    onSetTimeScale,
    onContinueBattle,
    onGameOver,
    softProfit,
    playDeckReward,
    playDeckProfit,
  } = props;

  const { configS, gameS } = useStores();
  const navigate = useNavigate();
  const [maxEnemyHp, setMaxEnemyHp] = useState<number>(0);

  // tutorial
  const [showIsReady, setShowIsReady] = useState<boolean>(false);
  const [isPauseOpen, setIsPauseOpen] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isBattleInitialized && !isPreGameOpen && !gameS?.gameInfo?.tutorialPassed) {
      setShowIsReady(true);
      setTimeout(function () {
        setShowIsReady(false);
      }, 2000);
    }
  }, [isPreGameOpen, gameS?.gameInfo?.tutorialPassed]);

  useEffect(() => {
    if (enemyHp > maxEnemyHp) {
      setMaxEnemyHp(enemyHp);
    }
  }, [enemyHp, isPreGameOpen]);

  const getPlayerHp = () => {
    if (playerHp <= 0) {
      return 0;
    }

    return (
      (playerHp * 100) /
      getFormula(getCurrentFormula(gameS?.gameInfo?.hpGrade, configS?.config?.playerHPFormula), [
        {
          key: 'N',
          value: gameS?.gameInfo?.hpGrade,
        },
      ])
    ).toFixed(1);
  };

  const getEnemyHp = () => {
    if (enemyHp <= 0) {
      return 0;
    }

    return ((enemyHp * 100) / maxEnemyHp).toFixed(1);
  };

  const onTabClick = (path: string) => {
    if (isGameOver || isPreGameOpen) {
      navigate(path);
      return;
    }
    onSetTimeScale(0);
    setIsPauseOpen(path);
  };

  const onReturnToBattle = () => {
    onSetTimeScale(1);
    setIsPauseOpen(undefined);
  };

  const onLeaveFromBattle = () => {
    onSetTimeScale(1);
    if (isPauseOpen) {
      gameS.fetchBattleResult().then(() => {
        navigate(isPauseOpen);
        setIsPauseOpen(undefined);
      });
    }
  };

  const maxTapsInPercents = () => {
    const maxCount = isPlayerStep
      ? getFormula(configS?.config?.tapMaxAttackCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapAttackGrade,
          },
        ])
      : getFormula(configS?.config?.tapMaxDefenseCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapDefenseGrade,
          },
        ]);

    if (tapsCount > maxCount) {
      return ((maxCount * 100) / configS?.config?.tapMaxCount).toFixed(0);
    }
    return ((tapsCount * 100) / configS?.config?.tapMaxCount).toFixed(0);
  };

  const gameUi = () => {
    return (
      <>
        <div className='combat-overlay'>
          <Currency
            onShopClick={onTabClick}
            onLeagueClick={onTabClick}
            onPauseClick={() => {
              onTabClick(ProjectRoutes.Pause);
            }}
          />
          <div className='health-container'>
            <div className='health-bar'>
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  maskRepeat: 'no-repeat',
                  maskPosition: 'left',
                  maskImage: `url(./assets/ui_fight_hp_left_bg.svg)`,
                  backgroundColor: 'rgba(0,0,0, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  className='health-bar-damage-progress'
                  style={{
                    right: 0,
                    width: `${getPlayerHp()}%`,
                    backgroundColor: '#FD8322',
                  }}
                />
                <div
                  className='health-bar-progress'
                  style={{
                    width: `${getPlayerHp()}%`,
                    right: 0,
                    backgroundColor: '#5AC8E6',
                  }}
                />
                <Typography.Text
                  className='health-text'
                  style={{
                    left: '8px',
                  }}
                >
                  {(playerHp > 0 ? playerHp.toFixed(1) : 0) + ' '}
                  <Typography.Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: '10px',
                      fontFamily: 'Kraft',
                      textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
                    }}
                  >
                    HP
                  </Typography.Text>
                </Typography.Text>
              </div>
            </div>
            <div className='health-bar'>
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  maskRepeat: 'no-repeat',
                  maskImage: `url(./assets/ui_fight_hp_right_bg.svg)`,
                  backgroundColor: 'rgba(0,0,0, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  maskPosition: 'right',
                }}
              >
                <div
                  className='health-bar-damage-progress'
                  style={{
                    left: 0,
                    width: `${getEnemyHp()}%`,
                    backgroundColor: '#FD8322',
                  }}
                />
                <div
                  className='health-bar-progress'
                  style={{
                    left: 0,
                    width: `${getEnemyHp()}%`,
                    backgroundColor: '#F6BE30',
                  }}
                />
                <Typography.Text
                  className='health-text'
                  style={{
                    right: '8px',
                  }}
                >
                  {(enemyHp > 0 ? enemyHp.toFixed(1) : 0) + ' '}
                  <Typography.Text
                    style={{
                      color: '#FFFFFF',
                      fontSize: '10px',
                      fontFamily: 'Kraft',
                      textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
                    }}
                  >
                    HP
                  </Typography.Text>
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className='round-box'>
            <Typography.Text
              style={{
                marginTop: '2px',
                color: '#FFFFFF',
                fontSize: '8px',
                textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
              }}
            >
              ROUND
            </Typography.Text>
            <Typography.Text
              style={{
                marginTop: '-7px',
                color: '#FFFFFF',
                fontSize: '24px',
                fontFamily: 'Kraft',
                textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
              }}
            >
              {round}
            </Typography.Text>
          </div>
          {Boolean(reward) && (
            <span
              className='reward-for-fight'
              style={{
                bottom: Boolean(playDeckReward) ? 'calc(-100vh / 3.5)' : 'calc(-100vh / 4)',
              }}
            >
              {`+${reward}`} <img src={coinImages[PriceEnum.SOFT]} alt='coin-icon' />
            </span>
          )}
          {Boolean(playDeckReward) && (
            <span className='reward-for-fight' style={{ bottom: 'calc(-100vh / 4)' }}>
              {`+${playDeckReward}`} <img src={coinImages[PriceEnum.PLAYCOIN]} alt='coin-icon' />
            </span>
          )}
          {amountOfDamage && (
            <span
              className='amount-of-damage'
              style={isPlayerStep ? { right: '36px' } : { left: '36px' }}
            >
              {amountOfDamage}
            </span>
          )}
        </div>
        <div className='tutorial-container'>
          <div
            className='tutorial-text-container'
            style={{
              margin: '0 auto 42px',
              transition: 'all 500ms linear',
              opacity: showIsReady ? 1 : 0,
              visibility: showIsReady ? 'visible' : 'hidden',
            }}
          >
            <Typography.Text
              style={{
                color: '#F6BE30',
              }}
              className='tutorial-text'
            >
              Ready to fight?
            </Typography.Text>
          </div>
          {isShowCombatButton && (
            <div style={{ position: 'relative' }}>
              {!gameS?.gameInfo?.tutorialPassed && (
                <div className='tutorial-text-container'>
                  <Typography.Text
                    style={{
                      color: '#F8F8F8',
                    }}
                    className='tutorial-text'
                  >
                    {isPlayerStep ? 'Tap to attack!' : 'Tap to defend!'}
                  </Typography.Text>
                </div>
              )}

              <div style={{ position: 'relative' }}>
                <div className='attack-button-container'>
                  <div
                    className='attack-button-background'
                    style={{
                      backgroundColor: isPlayerStep ? 'red' : 'blue',
                      top:
                        tapsCount > 0
                          ? `${
                              100 -
                              (tapsCount * 100) /
                                (isPlayerStep
                                  ? getFormula(configS?.config?.tapMaxAttackCountFormula, [
                                      {
                                        key: 'Y',
                                        value: gameS?.gameInfo?.tapAttackGrade,
                                      },
                                    ])
                                  : getFormula(configS?.config?.tapMaxDefenseCountFormula, [
                                      {
                                        key: 'Y',
                                        value: gameS?.gameInfo?.tapDefenseGrade,
                                      },
                                    ]))
                            }%`
                          : '100%',
                    }}
                  />
                  <div
                    className='attack-button'
                    style={{
                      backgroundImage: isPlayerStep
                        ? 'url(./assets/ui_icon_float_attack.svg)'
                        : 'url(./assets/ui_icon_float_defence.svg)',
                    }}
                  />
                </div>
                {gameS?.gameInfo?.tutorialPassed && (
                  <Typography.Text
                    style={{ color: '#FFFFFF', left: '20px' }}
                    className='text-near-button'
                  >
                    {isPlayerStep ? 'Attack!' : 'Defend!'}
                  </Typography.Text>
                )}
                {(tapsCount * 100) / configS?.config?.tapMaxCount !== 0 && (
                  <Typography.Text
                    style={{ color: isPlayerStep ? 'red' : 'blue', right: '64px' }}
                    className='text-near-button'
                  >
                    {`+${maxTapsInPercents()}%`}
                  </Typography.Text>
                )}
                {!gameS?.gameInfo?.tutorialPassed && tapsCount === 0 && (
                  <div className='tap-icon' />
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const preGameUi = () => {
    return (
      <div className='pre-load-overlay'>
        <div className='pre-load-person' />
        <div className='pre-load-currency'>
          <Currency />
        </div>

        {gameS?.gameInfo?.tutorialPassed && (
          <>
            <button className='start-game-button' onClick={() => navigate(ProjectRoutes.Pvp)}>
              <span style={{ fontSize: isBattleInitialized ? '32px' : '48px' }}>DUEL</span>
            </button>
            <div className='or-container'>
              <div />
              <span>OR</span>
              <div />
            </div>
          </>
        )}

        <button className='pvp-game-button' onClick={onStartBattleClick}>
          <span>FIGHT</span>
        </button>
        {gameS?.gameInfo?.tutorialPassed && (
          <>
            <Subscriptions />
          </>
        )}
      </div>
    );
  };

  const gameOverUi = () => {
    return (
      <div className='game-over-overlay'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Typography.Text style={{ color: '#FFFFFF', fontSize: '64px', fontFamily: 'Kraft' }}>
            GAME OVER!
          </Typography.Text>
          <div
            style={{ display: 'flex', gap: '16px', alignItems: 'center', flexDirection: 'column' }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {Boolean(playDeckProfit) && (
                <>
                  <img height='40px' src={coinImages[PriceEnum.PLAYCOIN]} alt='coin' />
                  <Typography.Text className='game-over-value'>{playDeckProfit}</Typography.Text>
                </>
              )}
              <img height='40px' src={coinImages[PriceEnum.SOFT]} alt='coin' />
              <Typography.Text className='game-over-value'>{softProfit}</Typography.Text>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography.Text className='game-over-text'>ROUND REACHED</Typography.Text>
              <Typography.Text className='game-over-value'>{round}</Typography.Text>
            </div>
          </div>
          <button className='get-reward-button' onClick={onStartBattleClick}>
            <Typography.Text className='game-over-button-text'>Get reward</Typography.Text>
          </button>
        </div>
      </div>
    );
  };

  const pauseUi = () => {
    return (
      <div className='game-over-overlay'>
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}
        >
          <Typography.Text
            style={{
              fontFamily: 'Barlow-Regular',
              color: '#FFFFFF',
              fontSize: '18px',
              textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
            }}
          >
            Leave battle?
          </Typography.Text>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '32px', alignItems: 'center' }}
          >
            <button className='get-reward-button' onClick={onReturnToBattle}>
              <Typography.Text className='game-over-button-text'>Return</Typography.Text>
            </button>
            <Typography.Text
              className='game-over-button-text'
              style={{ fontSize: '12px' }}
              onClick={onLeaveFromBattle}
            >
              Leave
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPreGameOpen && preGameUi()}
      {isBattleInitialized && !isPreGameOpen && gameUi()}
      {isGameOver && gameOverUi()}
      {isPauseOpen && pauseUi()}
      {isRevive && <ReviveUi onContinueBattle={onContinueBattle} onGameOver={onGameOver} />}
      {gameS?.gameInfo?.tutorialPassed && (
        <div className='ui-overlay__footer'>
          <Footer onTabClick={onTabClick} />
        </div>
      )}
    </>
  );
});
