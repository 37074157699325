import './PvpBetUi.css';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { useUnityLogicContext } from '../../../../context/UnityLogicProvider';
import { useStores } from '../../../../hooks/useStore';
import { ReactActions } from '../../../../types/actions';
import { LoadSceneEnum } from '../../../../types/scene';
import { PriceEnum } from '../../../../types/shop';
import { getFio } from '../../../../utils/users';
import { Currency } from '../../../Currency';
import { PvpFightUi } from '../PvpFightUi';
import { PvpLoading } from '../PvpLoading';
import { SearchOpponent } from '../SearchOpponent';
import type { IBet } from './Bet';
import { Bet } from './Bet';

export const PvpBetUi = observer(() => {
  const navigate = useNavigate();

  const [currentBet, setCurrentBet] = useState<IBet | undefined>(undefined);

  const { userS, gameS } = useStores();

  const {
    resetUi,
    sendMessage,
    isSearching,
    setIsSearching,
    isWaiting,
    isReady,
    setIsReady,
    loadScene,
  } = useUnityLogicContext();

  useEffect(() => {
    if (gameS?.bets?.length === 0 && !gameS?.request?.getBetOptions?.loader) {
      gameS?.fetchBetsInfo();
    }
  }, []);

  const goBackButton = () => {
    navigate(-1);
  };

  /*const onInviteButtonClick = () => {};*/

  const onPublicButtonClick = () => {
    resetUi();
    setIsSearching(true);
    sendMessage('ReactEventsHandler', ReactActions.PvPSearchStart, currentBet?.id?.toString());
  };

  const onCancelButtonClick = () => {
    sendMessage('ReactEventsHandler', ReactActions.PvPSearchCancel, currentBet?.id?.toString());
    setIsSearching(false);
    loadScene(LoadSceneEnum.Empty);
  };

  const betsFormatted = useMemo(() => {
    return gameS?.bets
      ?.slice()
      ?.sort((a, b) => a.bet - b.bet)
      .map((info) => {
        if (!info.bet) {
          setCurrentBet(info);
          return {
            isTutorial: true,
            ...info,
          };
        }
        return {
          isTutorial: false,
          ...info,
        };
      }) as IBet[];
  }, [gameS?.bets]);

  return (
    <>
      {!isReady && (
        <div className='pvp-fight-overlay'>
          <div className='pvp-fight-container'>
            <div className='header-container'>
              <button className='pvp-fight-back-button' onClick={goBackButton} />
              <Currency sticky />
            </div>

            <span className='pvp-title'>PvP BATTLE</span>
            <div className='pvp-choose-container'>
              <span>CHOOSE THE BET:</span>
              {/*<InfoIcon />*/}
            </div>
            <div className='line-decorator' />
            <div className='bet-list'>
              {betsFormatted?.map((item, index) => (
                <Bet
                  key={index}
                  isActive={currentBet?.id === item.id}
                  isTutorial={true}
                  onChooseBet={setCurrentBet}
                  {...item}
                />
              ))}
            </div>
            <div className='line-decorator' />
            <div className='pvp-user-info'>
              <span>{getFio(userS?.user?.last_name, userS?.user?.first_name)}</span>
              <div>
                <span>{`BS: ${Math.round(gameS?.gameInfo?.battlescore)}`}</span>
                <span>|</span>
                <span>{`WINS: ${gameS?.gameInfo?.pvpWins}`}</span>
              </div>
            </div>
            <div className='vs-container'>
              <div />
              <span>VS</span>
              <div />
            </div>
            <div className='buttons-container'>
              {/*<button
                style={{ backgroundImage: 'url(./assets/ui_invite-button.svg)' }}
                onClick={onInviteButtonClick}
              >
                <span>Invite</span>
              </button>
              <span>OR</span>*/}
              <button
                style={{ backgroundImage: 'url(./assets/ui_public-button.svg)' }}
                onClick={onPublicButtonClick}
              >
                <span>Public</span>
              </button>
            </div>
          </div>
        </div>
      )}

      {isSearching && <SearchOpponent onCancel={onCancelButtonClick} />}
      {isWaiting && <PvpLoading />}
      {isReady && (
        <PvpFightUi
          userName={getFio(userS?.user?.last_name, userS?.user?.first_name)}
          bet={currentBet}
          onFinish={async (coinType) => {
            gameS?.fetchGameInfo();
            if (coinType !== PriceEnum.SOFT) {
              gameS?.fetchPlayDeckProfile();
            }

            setIsReady(false);
            loadScene(LoadSceneEnum.Empty);
          }}
        />
      )}
    </>
  );
});
