import { useEffect } from 'react';
import { useCountdownTimer } from '../../hooks/useCountDownTimer';
import './Timer.css';

type TProps = {
  date: Date;
  forFight?: boolean;
  forAutoFight?: boolean;
  onExpire(): void;
};

const addLeadZero = (value: number) => {
  return value > 9 ? value : `0${value}`;
};

export const Timer = (props: TProps) => {
  const { date, forFight, forAutoFight, onExpire } = props;
  const [days, hours, minutes, seconds] = useCountdownTimer(date);

  useEffect(() => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      onExpire();
    }
  }, [minutes, seconds]);

  const renderTimer = () => {
    if (forAutoFight) {
      return `${days === 0 ? '' : addLeadZero(days) + ':'}${hours === 0 ? '' : addLeadZero(hours) + ':'}${minutes === 0 ? '' : addLeadZero(minutes) + ':'}${addLeadZero(seconds) === 0 ? '' : seconds}`;
    }

    if (!forFight) {
      return `${days === 0 ? '' : days + 'd '}${hours === 0 ? '' : hours + 'h '}${minutes === 0 ? '' : minutes + 'm '}${seconds === 0 ? '' : seconds + 's'}`;
    }

    if (days !== 0) {
      return `${days} days`;
    }

    if (hours !== 0) {
      return `${hours}h`;
    }

    if (minutes !== 0) {
      return `${minutes}m`;
    }

    return `${seconds}s`;
  };

  return <span className='timer-text'>{renderTimer()}</span>;
};
