 // Функция для преобразования строки времени сервера и выравнивания по времени клиента
 export const convertServerTimeToClientTime = (serverTime: Date | string): Date => {
  let serverDate;
    // Создаем объект даты из строки времени сервера
   if (serverTime instanceof Date) {
     serverDate = serverTime;
   }
   else {
     serverDate = new Date(serverTime);
   }

    // Определяем время сервера в часах (Москва UTC+3)
    const serverTimezoneOffset = -180; // Москва UTC+3 в минутах

    // Вычисляем время клиента
    const clientTimezoneOffset = new Date().getTimezoneOffset(); // В минутах

    // Выравниваем время: клиентское время = время сервера + разница часовых поясов
    const adjustedTime = new Date(
      serverDate.getTime() + (clientTimezoneOffset - serverTimezoneOffset) * 60000
    );

    return adjustedTime;
  };

