import './PvpLoading.css';
import { useEffect, useState } from 'react';

const data = ['Preparing the battle...', 'Loading textures...', 'Cleaning weapons...'];
const numbers = [0, 9, 21, 32, 45, 67, 83, 97];

export const PvpLoading = () => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (progress === 7) {
      return;
    }

    const interval = setInterval(() => {
      setProgress((prevState) => prevState + 1);
    }, 500);

    return () => clearInterval(interval);
  }, [progress]);

  const getText = (time: number) => {
    if (time < 30) {
      return data[0];
    } else if (time < 60) {
      return data[1];
    } else {
      return data[2];
    }
  };

  return (
    <div className='pvp-loading-overlay'>
      <div
        style={{ top: 0, left: 0, right: 0, transform: 'rotate(-180deg)' }}
        className='pvp-loading-border'
      />
      <div style={{ bottom: 0, left: 0, right: 0 }} className='pvp-loading-border' />
      <span style={{ top: '140px', color: '#19C8FA' }}>LOADING</span>
      <div className='pvp-loading-bar'>
        <div
          style={{ width: `${(234 * numbers[progress]) / 100}px` }}
          className='pvp-loading-bar-active'
        />
        <span>{numbers[progress]}%</span>
        <div
          style={{
            top: '-24px',
            left: '-24px',
          }}
          className='pvp-angle'
        />
        <div
          style={{
            bottom: '-24px',
            right: '-24px',
            transform: 'rotate(-180deg)',
          }}
          className='pvp-angle'
        />
      </div>
      <span style={{ bottom: '134px', color: '#FFF' }}>{getText(progress)}</span>
    </div>
  );
};
