import type { AxiosInstance } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { BattleInfo, BetInfo, GameInfo, PaidRevive, UserSubscription } from '../models/game';
import type { PlayDeckProfile, PlayDeckWallet } from '../types/playDeck';
import type { TShopItem } from '../types/shop';
import { PriceEnum } from '../types/shop';
import type { SkillType } from '../types/skills';
import { ModelTypeEnum } from '../types/upgrade';
import { RequestStore } from './RequestStore';

type Props = {
  axiosInstance: AxiosInstance;
};

export class GameStore {
  request;
  private readonly client: AxiosInstance;

  public constructor(props: Props) {
    this.client = props.axiosInstance;

    this.request = {
      gameInfo: new RequestStore<GameInfo>({
        client: this.client,
        url: '/game_info',
        method: 'get',
      }),
      battleResult: new RequestStore<GameInfo>({
        client: this.client,
        url: '/battle_result',
        method: 'post',
      }),
      upgrade: new RequestStore<GameInfo>({
        client: this.client,
        url: '/upgrade/${type}',
        method: 'get',
      }),
      buyModelArmor: new RequestStore<GameInfo>({
        client: this.client,
        url: `/buy/armor`,
        method: 'get',
      }),
      buyModelWeapon: new RequestStore<GameInfo>({
        client: this.client,
        url: `/buy/weapon`,
        method: 'get',
      }),
      mergeArmor: new RequestStore<GameInfo>({
        client: this.client,
        url: `/merge/armor`,
        method: 'get',
      }),
      mergeWeapon: new RequestStore<GameInfo>({
        client: this.client,
        url: `/merge/weapon`,
        method: 'get',
      }),
      tutorialComplete: new RequestStore<GameInfo>({
        client: this.client,
        url: `/tutorial`,
        method: 'get',
      }),
      dropTutorial: new RequestStore<GameInfo>({
        client: this.client,
        url: `/drop_tutorial`,
        method: 'post',
      }),
      getShopItems: new RequestStore<TShopItem[]>({
        client: this.client,
        url: '/get_shop_items',
        method: 'get',
      }),
      buyShopItem: new RequestStore<GameInfo | UserSubscription[]>({
        client: this.client,
        url: '/shop/buy/${id}',
        method: 'get',
      }),
      startBattle: new RequestStore<BattleInfo>({
        client: this.client,
        url: '/start_battle/',
        method: 'get',
      }),
      nextRound: new RequestStore<BattleInfo>({
        client: this.client,
        url: '/next_round',
        method: 'post',
      }),
      freeResurrection: new RequestStore<BattleInfo>({
        client: this.client,
        url: '/free_resurrection',
        method: 'post',
      }),
      resurrection: new RequestStore<BattleInfo>({
        client: this.client,
        url: '/resurrection',
        method: 'post',
      }),
      buyResurrection: new RequestStore<PaidRevive>({
        client: this.client,
        url: '/buy_resurrection',
        method: 'post',
      }),
      getUserSubs: new RequestStore<UserSubscription[]>({
        client: this.client,
        url: '/get_user_subs',
        method: 'get',
      }),
      paymentLink: new RequestStore<{ link: string }>({
        client: this.client,
        url: '/payment_link/${shopItemId}',
        method: 'get',
      }),
      claimAutoFightReward: new RequestStore<GameInfo>({
        client: this.client,
        url: '/auto_fight_reward',
        method: 'get',
      }),
      playDeckProfile: new RequestStore<PlayDeckProfile>({
        client: this.client,
        url: '/playdeck_profile',
        method: 'get',
      }),
      getBetOptions: new RequestStore<BetInfo[]>({
        client: this.client,
        url: '/bet_options',
        method: 'get',
      }),
      getAutoFarmDelayedReward: new RequestStore<GameInfo>({
        client: this.client,
        url: '/autofarm_delayed_reward',
        method: 'post',
      }),
    };
    makeAutoObservable(this);
  }

  private _userSubs?: UserSubscription[];
  private _battleInfo?: BattleInfo;
  private _gameInfo: GameInfo = {
    autofarmGrade: 0,
    autofarmClaimed: new Date(),
    hardCoins: 0,
    freeResurrectionCount: 0,
    softCoins: 0,
    tutorialPassed: new Date(),
    weaponGrade: 0,
    pvpWins: 0,
    armorGrade: 0,
    armorPurchased: false,
    battlescore: 0,
    hpGrade: 0,
    maxRound: 0,
    resurrectionCount: 0,
    tapAttackGrade: 0,
    tapDefenseGrade: 0,
    weaponPurchased: false,
    dailyResurrectionCount: 0,
    dailyBonusClaimed: false,
    dailyLoginDay: 0,
    delayedReward: 0,
    leaguePlace: 0,
    leagueId: 0,
    kills: 0,
  };
  private _shop?: TShopItem[];
  private _playDeckProfile?: PlayDeckProfile;
  private _playDeckWallet?: PlayDeckWallet;
  private _bets: BetInfo[] = [];

  private _round: number = 1;

  get bets() {
    return this._bets;
  }

  private setBets(value: typeof this._bets) {
    this._bets = value;
  }

  get playDeckProfile() {
    return this._playDeckProfile;
  }

  private setPlayDeckProfile(value: typeof this._playDeckProfile) {
    this._playDeckProfile = value;
  }

  get playDeckWallet() {
    return this._playDeckWallet;
  }

  private setPlayDeckWallet(value: typeof this._playDeckWallet) {
    this._playDeckWallet = value;
  }

  get userSubs() {
    return this._userSubs;
  }

  public setUserSubs(value: typeof this._userSubs) {
    this._userSubs = value;
  }

  get shop() {
    return this._shop;
  }

  private setShop(value: typeof this._shop) {
    this._shop = value;
  }

  get gameInfo(): typeof this._gameInfo {
    return this._gameInfo;
  }

  public setGameInfo(value: typeof this._gameInfo) {
    this._gameInfo = value;
  }

  get battleInfo() {
    return this._battleInfo;
  }

  private setBattleInfo(value: typeof this._battleInfo) {
    this._battleInfo = value;
  }

  public async fetchGameInfo() {
    await this.request.gameInfo.request().then(() => {
      const { data } = this.request.gameInfo.result;

      if (data) {
        this.setGameInfo(data);
      }
    });
  }

  public async fetchBattleResult(hasPlayDeckProfit: boolean = false) {
    await this.request.battleResult
      .request({ data: { id: this._battleInfo?.id, round: this._round } })
      .then(() => {
        const { data } = this.request.battleResult.result;

        if (data) {
          if (!data.tutorialPassed) {
            this.completeTutorial();
          }

          this.setGameInfo(data);

          if (hasPlayDeckProfit) {
            this.fetchPlayDeckProfile();
          }
        }
      });
  }

  public async upgradeSkill(type: SkillType) {
    await this.request.upgrade.request({ urlProps: { type } }).then(() => {
      const { data } = this.request.upgrade.result;

      if (data) {
        this.setGameInfo(data);
      }
    });
  }

  public async buyModel(type: ModelTypeEnum) {
    const request =
      type === ModelTypeEnum.ARMOR ? this.request.buyModelArmor : this.request.buyModelWeapon;
    await request.request().then(() => {
      const { data } = request.result;

      if (data) {
        this.setGameInfo(data);
        return data;
      }
      return undefined;
    });
  }

  public async mergeItems(type: ModelTypeEnum) {
    const request =
      type === ModelTypeEnum.ARMOR ? this.request.mergeArmor : this.request.mergeWeapon;
    await request.request().then(() => {
      const { data } = request.result;

      if (data) {
        this.setGameInfo(data);
      }
    });
  }

  public async completeTutorial() {
    await this.request.tutorialComplete.request().then(() => {
      const { data } = this.request.tutorialComplete.result;

      if (data) {
        this.setGameInfo(data);
      }
    });
  }

  public async dropTutorial() {
    await this.request.dropTutorial.request().then(() => {
      const { data } = this.request.dropTutorial.result;

      if (data) {
        this.setGameInfo(data);
      }
    });
  }

  public async getShopItems() {
    await this.request.getShopItems.request().then(() => {
      const { data } = this.request.getShopItems.result;

      if (data) {
        this.setShop(data);
      }
    });
  }

  public async buyShopItem(item: TShopItem) {
    await this.request.buyShopItem.request({ urlProps: { id: item.id } }).then(() => {
      const { data } = this.request.buyShopItem.result;

      if (data) {
        if (item.itemType.includes('_SUB')) {
          this.setUserSubs(data as UserSubscription[]);
          this.setGameInfo({
            ...this._gameInfo,
            softCoins: this._gameInfo.softCoins - item.price,
          });
        } else {
          this.setGameInfo(data as GameInfo);
        }
      }
    });
  }

  public async getPaymentLink(shopItemId: number) {
    return await this.request.paymentLink.request({ urlProps: { shopItemId } }).then(() => {
      const { data } = this.request.paymentLink.result;

      if (data) {
        return data.link;
      }
      return false;
    });
  }

  public async startBattle() {
    await this.request.startBattle.request({}).then(() => {
      const { data, status } = this.request.startBattle.result;

      if (status && data) {
        this._round = data.startRound;
        this.setBattleInfo(data);
      }
    });
  }

  public async nextRound() {
    return await this.request.nextRound.request({ data: { id: this._battleInfo?.id } }).then(() => {
      const { data, status } = this.request.nextRound.result;

      if (data && status) {
        this._round += 1;
        this.setBattleInfo(data);
        this.setGameInfo({
          ...this.gameInfo,
          softCoins: this.gameInfo.softCoins + data.roundReward,
        });
        if (this.playDeckWallet) {
          this.setPlayDeckWallet({
            ...this.playDeckWallet,
            balance: this.playDeckWallet.balance + data.playDeckReward,
          });
        }
        return data;
      }

      return undefined;
    });
  }

  public async getUserSubs() {
    await this.request.getUserSubs.request().then(() => {
      const { data } = this.request.getUserSubs.result;

      if (data) {
        this.setUserSubs(data);
      }
    });
  }

  // revive

  private getUpdatedResurrections() {
    if (this._gameInfo?.dailyResurrectionCount) {
      return {
        dailyResurrectionCount: this._gameInfo?.dailyResurrectionCount - 1,
        freeResurrectionCount: this._gameInfo?.freeResurrectionCount,
      };
    }

    return {
      dailyResurrectionCount: this._gameInfo?.dailyResurrectionCount,
      freeResurrectionCount: this._gameInfo?.freeResurrectionCount - 1,
    };
  }

  public async freeRevive() {
    return await this.request.freeResurrection
      .request({ data: { id: this._battleInfo?.id } })
      .then(() => {
        const { data } = this.request.freeResurrection.result;

        if (data) {
          this.setBattleInfo(data);
          this.setGameInfo({
            ...this.gameInfo,
            ...this.getUpdatedResurrections(),
          });
          return true;
        }
        return false;
      });
  }

  public async paidRevive(payment_id: string) {
    return await this.request.resurrection
      .request({ data: { id: this._battleInfo?.id, payment_id } })
      .then(() => {
        const { data } = this.request.resurrection.result;

        if (data) {
          this.setBattleInfo(data);
          return true;
        }
        return false;
      });
  }

  public async buyResurrection() {
    return await this.request.buyResurrection
      .request({ data: { id: this._battleInfo?.id } })
      .then(() => {
        const { data } = this.request.buyResurrection.result;

        if (data) {
          return data;
        }
        return undefined;
      });
  }

  public async claimAutoFightReward() {
    return await this.request.claimAutoFightReward.request().then(() => {
      const { data } = this.request.claimAutoFightReward.result;

      if (data) {
        this.setGameInfo(data);
        return true;
      }

      return false;
    });
  }

  public async fetchPlayDeckProfile() {
    return await this.request.playDeckProfile.request().then(() => {
      const { data } = this.request.playDeckProfile.result;

      if (data) {
        this.setPlayDeckProfile(data);
        this.setPlayDeckWallet(data.wallets.find((wallet) => wallet.type === PriceEnum.PLAYCOIN));
        return true;
      }

      return false;
    });
  }

  public async fetchBetsInfo() {
    return await this.request.getBetOptions.request().then(() => {
      const { data } = this.request.getBetOptions.result;

      if (data) {
        this.setBets(data);
        return true;
      }

      return false;
    });
  }

  public async getAutoFarmDelayerReward() {
    return await this.request.getAutoFarmDelayedReward.request().then(() => {
      const { data, status } = this.request.getAutoFarmDelayedReward.result;

      if (data && status) {
        this.setGameInfo(data);
      }
    });
  }
}
