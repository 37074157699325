import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const UpgradeArrowSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26 21.9803V25.5535C26 25.8 25.8003 26 25.5535 26H20.6429C17.8866 26.0075 15.2696 24.7893 13.5001 22.6746C11.7307 24.7893 9.11367 26.0075 6.35737 26H1.4465C1.19999 26 1 25.8 1 25.5535V21.9803C1 21.7338 1.19999 21.5339 1.4465 21.5339H6.35737C9.06803 21.5306 11.2648 19.3329 11.268 16.621V8.13495H9.03577C8.78926 8.13524 8.58956 7.9356 8.58927 7.68904C8.58898 7.59577 8.61834 7.50455 8.6727 7.42878L13.1368 1.17611C13.2955 0.975302 13.5865 0.941203 13.7871 1.09975C13.8153 1.12219 13.8408 1.14755 13.8632 1.17611L18.3276 7.42878C18.4709 7.62929 18.425 7.90791 18.2247 8.05159C18.1488 8.1058 18.0578 8.13495 17.9642 8.13495H15.7323V16.621C15.7352 19.3329 17.9323 21.5306 20.6429 21.5339H25.5535C25.8003 21.5339 26 21.7338 26 21.9803Z'
      fill='white'
      stroke='black'
    />
  </svg>
);

export const UpgradeArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UpgradeArrowSvg} {...props} />
);
