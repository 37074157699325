import React, { useEffect } from 'react';
import { useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useStores } from '../../hooks/useStore';
import './ReferralUi.css';
// eslint-disable-next-line import/order
import { Typography } from 'antd';
import { ReferralItem, ReferralReward } from '../Referals';

export const ReferralUi = observer(() => {
  const webApp = useWebApp();
  const [initDataUnsafe] = useInitData();
  const { userS, configS } = useStores();

  const inviteLink = `https://t.me/${process.env.REACT_APP_BOT_USERNAME}?start=rp_${initDataUnsafe?.user?.id}`;

  const onOpenTgLink = () => {
    webApp.openTelegramLink(
      `https://t.me/share/url?url=${inviteLink}&text=Join to the first 3D fighting!`,
    );
  };

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      toast.success('Link copied to clipboard', {
        toastId: 'link-copy',
        closeButton: false,
        icon: false,
        progress: undefined,
      });
    });
  };

  useEffect(() => {
    userS.fetchReferrals().then();
  }, []);

  return (
    <>
      <div className='referral-ui-container'>
        <div className='referral-header'>
          <Typography.Text className='referral-header-title' style={{ fontSize: '20px' }}>
            Invite friends!
          </Typography.Text>
          <Typography.Text style={{ marginTop: '-16px' }} className='referral-header-title'>
            You and your friends will get bonuses!
          </Typography.Text>
          <ReferralReward
            title='Invite a friend'
            icon='./assets/ui_telegram-icon.png'
            reward={configS?.config?.refReward}
            playCoinReward={configS?.config?.PDC_INVITE_FRIEND}
          />
          <ReferralReward
            title='Invite a friend with Telegram Premium'
            icon='./assets/ui_logo_tgpremium.png'
            reward={configS?.config?.refTgPremReward}
            playCoinReward={configS?.config?.PDC_INVITE_PREMIUM_FRIEND}
          />
          <Typography.Text className='referral-header-title'>
            {`List of your friends ${userS?.referrals?.length > 0 ? '(' + userS?.referrals?.length + ')' : ''}`}
          </Typography.Text>
          {userS?.referrals?.length !== 0 ? (
            <div className='referrals-container'>
              {userS?.referrals?.map((referral, index) => {
                return (
                  <ReferralItem
                    key={index}
                    user={referral}
                    reward={
                      referral?.isPremium
                        ? configS?.config?.refTgPremReward
                        : configS?.config?.refReward
                    }
                  />
                );
              })}
            </div>
          ) : undefined}
        </div>
        <div className='referral-buttons'>
          <button className='referral-invite-button' onClick={onOpenTgLink}>
            <Typography.Text>INVITE A FRIEND!</Typography.Text>
          </button>
          <button className='referral-copy-button' onClick={copyInviteLink} />
        </div>
      </div>
    </>
  );
});
