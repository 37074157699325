import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const RockSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
    <path
      d='M13.3529 15.4211C13.3529 13.5317 14.9331 12 16.8824 12H27.7647C30.1038 12 32 13.838 32 16.1053V26.3684C32 32.7924 26.6274 38 20 38C13.3726 38 8 32.7924 8 26.3684V19.2526C8 17.1365 9.76979 15.4211 11.9529 15.4211H13.3529Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M31.9967 26.6421H32V26.386C32 26.3801 32 26.3743 32 26.3684V16.1053C32 13.838 30.1038 12 27.7647 12H16.8824C14.9331 12 13.3529 13.5317 13.3529 15.4211H11.9529C9.76979 15.4211 8 17.1365 8 19.2526V26.3684C8 32.7924 13.3726 38 20 38C26.533 38 31.8467 32.9396 31.9967 26.6421ZM30.5882 21.7165V16.1053C30.5882 14.5937 29.3241 13.3684 27.7647 13.3684H26.2118V20.279H27.0588C28.4414 20.279 29.6914 20.8294 30.5882 21.7165ZM30.5882 26.3837C30.5797 32.0448 25.8425 36.6316 20 36.6316C14.1523 36.6316 9.41176 32.0366 9.41176 26.3684V19.2526C9.41176 17.8923 10.5495 16.7895 11.9529 16.7895H13.3529V15.4211L13.353 20.4676C11.9226 20.9415 10.8941 22.2556 10.8941 23.8026C10.8941 25.7487 12.5217 27.3263 14.5294 27.3263H17.6706C17.9825 27.3263 18.2353 27.5714 18.2353 27.8737V31.7053H19.647V27.8737C19.647 26.8156 18.7621 25.9579 17.6706 25.9579H14.5294C13.3014 25.9579 12.3059 24.993 12.3059 23.8026C12.3059 22.6123 13.3014 21.6474 14.5294 21.6474H27.0588C29.0081 21.6474 30.5882 23.179 30.5882 25.0684V26.3837ZM14.7647 20.279V15.421C14.7647 14.2874 15.7128 13.3684 16.8824 13.3684H18.5765V20.279H14.7647ZM19.9882 20.279V13.3684H24.8V20.279H19.9882Z'
      fill='black'
    />
  </svg>
);

export const RockIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RockSvg} {...props} />
);
