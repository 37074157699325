import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import type { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';
import { Loader } from '../components/main/Loader';
import { ReactActions, UnityCallbacks } from '../types/actions';
import type { PvpRoundData } from '../types/pvp';
import { GameState, PvpChoice, RoundResultEnum } from '../types/pvp';
import type { LoadSceneEnum } from '../types/scene';

const instance: {
  gameState: GameState;
  pvpRound: number;
  isWaiting: boolean;
  isSceneLoaded: boolean;
  isPhaseStarted: boolean;
  roundData: undefined | PvpRoundData;
  roundResult: RoundResultEnum;
  signType: PvpChoice;
  phaseTimer: number;
  setSignType: (value: PvpChoice) => void;
  isReady: boolean;
  setIsReady: (value: boolean) => void;
  isSearching: boolean;
  setIsSearching: (value: boolean) => void;
  setIsSceneLoaded: (value: boolean) => void;
  isLoaded: boolean;
  battleWinner: string | undefined;
  loadScene: (scene: LoadSceneEnum) => void;
  addEventListener: (
    eventName: string,
    callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter,
  ) => void;
  removeEventListener: (
    eventName: string,
    callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter,
  ) => void;
  sendMessage: (
    gameObjectName: string,
    methodName: string,
    parameter?: ReactUnityEventParameter,
  ) => void;
  resetUi: () => void;
} = {
  isPhaseStarted: false,
  gameState: GameState.None,
  pvpRound: 0,
  isWaiting: false,
  isSceneLoaded: false,
  signType: PvpChoice.None,
  roundData: {
    round: 0,
    playerWins: 0,
    enemySign: PvpChoice.None,
    enemyWins: 0,
  },
  resetUi: () => {},
  battleWinner: undefined,
  roundResult: RoundResultEnum.NONE,
  phaseTimer: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSignType: (value: PvpChoice) => {},
  isReady: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsReady: (value: boolean) => {},
  isSearching: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsSearching: (value: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsSceneLoaded: (value: boolean) => {},
  isLoaded: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadScene: (scene: LoadSceneEnum) => {},
  addEventListener: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter,
  ) => {},
  removeEventListener: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eventName: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (...parameters: ReactUnityEventParameter[]) => ReactUnityEventParameter,
  ) => {},
  sendMessage: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    gameObjectName: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    methodName: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parameter?: ReactUnityEventParameter,
  ) => {},
};

const UnityLogicContext = createContext(instance);

function handleCacheControl(url: string) {
  if (url.match(/\.bundle/)) {
    return 'must-revalidate';
  }
  return 'no-store';
}

const defaultData: PvpRoundData = {
  round: 0,
  playerWins: 0,
  enemySign: PvpChoice.None,
  enemyWins: 0,
};

export const UnityLogicProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [isSceneLoaded, setIsSceneLoaded] = useState<boolean>(false);
  const [battleWinner, setBattleWinner] = useState<string | undefined>(undefined);
  const [isPhaseStarted, setIsPhaseStarted] = useState<boolean>(false);
  const [roundData, setRoundData] = useState<PvpRoundData>(defaultData);
  const [gameState, setGameState] = useState<GameState>(GameState.None);
  const [roundResult, setRoundResult] = useState<RoundResultEnum>(RoundResultEnum.NONE);

  const [pvpRound, setPvpRound] = useState<number>(0);
  const [signType, setSignType] = useState<PvpChoice>(PvpChoice.None);
  const [phaseTimer, setPhaseTimer] = useState<number>(0);

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);
      mediaMatcher.addEventListener('change', updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
      };
    },
    [devicePixelRatio],
  );

  useEffect(() => {
    if (gameState === 2) {
      setSignType(PvpChoice.None);
    }
  }, [gameState]);

  const unityBuildSalt = process.env.REACT_APP_UNITY_BUILD_SALT ?? '';
  const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded } =
    useUnityContext({
      loaderUrl: `./unity_build${unityBuildSalt}/PunchOut.loader.js`,
      dataUrl: `./unity_build${unityBuildSalt}/PunchOut.data`,
      frameworkUrl: `./unity_build${unityBuildSalt}/PunchOut.framework.js`,
      codeUrl: `./unity_build${unityBuildSalt}/PunchOut.wasm`,
      streamingAssetsUrl: `./StreamingAssets${unityBuildSalt}`,
      cacheControl: handleCacheControl,
    });

  const handleGamsStateChanged = (gameState: GameState) => {
    console.log(gameState, ' gameState');
    setGameState(gameState);
  };

  const handleRoundEnd = (data: string) => {
    console.log(data);
    const formattedData = JSON.parse(data);

    if (formattedData.enemyWins > roundData.enemyWins) {
      setRoundResult(RoundResultEnum.LOSE);
    } else if (formattedData.playerWins > roundData.playerWins) {
      setRoundResult(RoundResultEnum.WIN);
    } else {
      setRoundResult(RoundResultEnum.DRAW);
    }

    setPvpRound(formattedData.round);
    setRoundData(formattedData);
  };

  const handleBattleEnd = (winnerId: string) => {
    setBattleWinner(winnerId);
    console.log(winnerId);
  };

  const resetUi = () => {
    setPvpRound(0);
    setRoundData(defaultData);
    setRoundResult(RoundResultEnum.NONE);
    setSignType(PvpChoice.None);
    setBattleWinner(undefined);
    setIsPhaseStarted(false);
  };

  const handleGameReady = (ready: boolean) => {
    if (ready) {
      // костыль
      setIsSearching(false);
      setIsWaiting(false);
      setIsReady(true);
    }
    console.log('gameReady ', ready);
  };

  const handleSearchEnd = (enemy: string) => {
    console.log('enemy: ', enemy);
    setIsSearching(false);
    setIsWaiting(true);
  };

  const handleNetworkError = () => {};

  const handlePhaseTimerChanged = (timer: number) => {
    setPhaseTimer(timer);

    console.log('phase123 ', timer);
  };

  const handleReadyTimerChanged = (timer: number) => {
    if (timer === 0) {
      setIsPhaseStarted(true);
    }
    setPhaseTimer(timer);
    console.log('ready123 ', timer);
  };

  useEffect(() => {
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPBattleEnd, handleBattleEnd);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPGameReady, handleGameReady);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPGameStateChanged, handleGamsStateChanged);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPRoundEnd, handleRoundEnd);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPPreparePhaseTimerChanged, handlePhaseTimerChanged);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPReadyTimerChanged, handleReadyTimerChanged);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPSearchEnd, handleSearchEnd);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPNetworkError, handleNetworkError);

    return () => {
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPBattleEnd, handleBattleEnd);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPGameReady, handleGameReady);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPGameStateChanged, handleGamsStateChanged);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPRoundEnd, handleRoundEnd);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPPreparePhaseTimerChanged, handlePhaseTimerChanged);
      // @ts-ignore
      addEventListener(UnityCallbacks.OnPvPReadyTimerChanged, handleReadyTimerChanged);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPSearchEnd, handleSearchEnd);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPNetworkError, handleNetworkError);
    };
  });

  useEffect(() => {
    if (isLoaded) {
      sendMessage(
        'ReactEventsHandler',
        ReactActions.SetAdressablesRemoteURL,
        'https://models.cdn.punchoutcrypto.com/WebGL',
      );
    }
  }, [isLoaded]);

  const handlePvpRoundChange = (round: number) => {
    setPvpRound(round);
  };

  useEffect(() => {
    // @ts-ignore
    addEventListener(UnityCallbacks.OnPvPRoundChanged, handlePvpRoundChange);
    return () => {
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnPvPRoundChanged, handlePvpRoundChange);
    };
  }, []);

  const loadScene = (scene: LoadSceneEnum) => {
    setIsSceneLoaded(false);
    sendMessage('ReactEventsHandler', scene);
  };

  return (
    <UnityLogicContext.Provider
      value={{
        resetUi,
        isPhaseStarted,
        roundResult,
        roundData,
        battleWinner,
        phaseTimer,
        setSignType,
        signType,
        isReady,
        setIsReady,
        isWaiting,
        gameState,
        pvpRound,
        isSceneLoaded,
        isSearching,
        setIsSearching,
        loadScene,
        addEventListener,
        removeEventListener,
        sendMessage,
        isLoaded,
        setIsSceneLoaded,
      }}
    >
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={devicePixelRatio}
        style={{
          width: '100vw',
          height: '100vh',
          overflow: 'hidden',
          position: 'absolute',
          zIndex: 0,
          visibility: isLoaded ? 'visible' : 'hidden',
        }}
      />
      {(!isLoaded || !isSceneLoaded) && <Loader />}
      {children}
    </UnityLogicContext.Provider>
  );
};

export const useUnityLogicContext = () => {
  const loading = useContext(UnityLogicContext);

  if (!loading) {
    throw new Error(
      'The component must be wrapped in UnityLogicContext to use useUnityLogicContext',
    );
  }

  return loading;
};
