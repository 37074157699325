import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const PaperSvg = () => (
  <svg width='28' height='36' viewBox='0 0 28 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.98353 5.44651C2.04919 5.44651 1.29175 6.18723 1.29175 7.10095V25.9041C1.29175 30.7462 5.30571 34.6716 10.2572 34.6716C12.5573 34.6716 14.6551 33.8246 16.2422 32.4321L16.2709 32.4601L25.8025 23.1388C26.8324 22.1317 26.8324 20.4987 25.8025 19.4916C24.7726 18.4844 23.1028 18.4844 22.0729 19.4916L19.2226 22.2789V6.12409C19.2226 4.79611 18.1218 3.71957 16.7638 3.71957H16.6202C15.5593 3.71957 14.6388 4.43564 14.4003 5.44651V3.57331C14.4003 2.3335 13.3726 1.32843 12.1048 1.32843C10.8801 1.32843 9.87107 2.26858 9.81188 3.46482L9.71383 5.44651V3.52031C9.71383 2.30977 8.71034 1.32843 7.47247 1.32843C6.2346 1.32843 5.23111 2.30977 5.23111 3.52031V5.44651V6.77492L5.20708 6.73221C4.76108 5.9392 3.909 5.44651 2.98353 5.44651Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.3375 32.4601L16.3089 32.432C14.7217 33.8245 12.6239 34.6716 10.3238 34.6716C5.37236 34.6716 1.3584 30.7462 1.3584 25.9041V7.10093C1.3584 6.18721 2.11584 5.44649 3.05018 5.44649C3.33451 5.44649 3.61191 5.493 3.87274 5.58043V16.0738H5.23049V6.65833C5.2453 6.68265 5.25972 6.70727 5.27374 6.73219L5.29776 6.77491V3.5203C5.29776 2.30975 6.30125 1.32841 7.53912 1.32841C8.08607 1.32841 8.58726 1.52 8.97635 1.83829C8.99607 1.85442 9.0155 1.87088 9.03463 1.88765V16.0738H10.3924V2.15442C10.4865 2.04168 10.5916 1.93812 10.706 1.84526C11.1044 1.522 11.6157 1.32841 12.1714 1.32841C13.2949 1.32841 14.0941 2.11771 14.2923 3.16022C14.3178 3.29411 14.3311 3.43218 14.3311 3.57329V5.44649C14.3315 5.44474 14.3319 5.44298 14.3324 5.44123V16.0738H15.6901V3.94308C15.735 3.92228 15.7802 3.90283 15.8254 3.88476C16.0927 3.77789 16.384 3.71956 16.6868 3.71956H16.8305C18.1884 3.71956 19.2893 4.7961 19.2893 6.12408V22.2789L22.1396 19.4915C23.1695 18.4844 24.8393 18.4844 25.8692 19.4915C26.8991 20.4987 26.8991 22.1316 25.8692 23.1388L16.3375 32.4601ZM20.6477 19.0718V6.12408C20.6477 4.06243 18.9387 2.39114 16.8305 2.39114H16.6868C16.335 2.39114 15.9928 2.44048 15.6681 2.53312C15.2127 1.06699 13.8195 0 12.1714 0C11.2899 0 10.4786 0.306013 9.84551 0.817353C9.22088 0.30708 8.41667 0 7.53912 0C5.55103 0 3.93936 1.57609 3.93936 3.5203V4.21853C3.6514 4.15252 3.35336 4.11808 3.05018 4.11808C1.36562 4.11808 0 5.45355 0 7.10093V25.9041C0 31.4799 4.62214 36 10.3238 36C12.5067 36 14.5332 35.3363 16.2011 34.2053L16.3375 34.3387L26.8297 24.0781C28.3901 22.5522 28.3901 20.0781 26.8297 18.5522C25.2693 17.0263 22.7394 17.0263 21.179 18.5522L20.6477 19.0718ZM10.1214 25.572C10.1214 23.0041 12.25 20.9225 14.8758 20.9225H19.2226V22.2509H14.8758C13.0002 22.2509 11.4798 23.7378 11.4798 25.572V28.3616H10.1214V25.572Z'
      fill='black'
    />
  </svg>
);

export const PaperIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PaperSvg} {...props} />
);
