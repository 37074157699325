/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { CombatUi } from '../CombatUi';
import { observer } from 'mobx-react';
import { LoadSceneEnum, SceneTypeEnum } from '../../types/scene';
import { useStores } from '../../hooks/useStore';
import { useLocation } from 'react-router';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { ReactActions, UnityCallbacks } from '../../types/actions';
import { UpgradeUi } from '../UpgradeUi';
import { ModelTypeEnum, ModelTypesActions } from '../../types/upgrade';
import { getCurrentFormula, getFormula } from '../../utils/formulas';
import { useUnityLogicContext } from '../../context/UnityLogicProvider';
import { PvpInitData } from '../../types/pvp';
import { toast } from 'react-toastify';
import { PvpLoading } from '../CombatUi/PvpUi/PvpLoading';

const sceneForRoute = {
  [ProjectRoutes.League]: LoadSceneEnum.Empty,
  [ProjectRoutes.Home]: LoadSceneEnum.Empty,
  [ProjectRoutes.Shop]: LoadSceneEnum.Empty,
  [ProjectRoutes.Pause]: LoadSceneEnum.Empty,
  [ProjectRoutes.Quests]: LoadSceneEnum.Empty,
  [ProjectRoutes.Combat]: LoadSceneEnum.Empty,
  [ProjectRoutes.Upgrade]: LoadSceneEnum.MergeScene,
  [ProjectRoutes.Pvp]: LoadSceneEnum.PvpScene,
};

export const UnityContainer = observer(() => {
  const { configS, gameS, tokenS } = useStores();
  const { pathname } = useLocation();
  const { setIsSceneLoaded, sendMessage, addEventListener, removeEventListener, loadScene } =
    useUnityLogicContext();

  const [isShowCombatButton, setIsShowCombatButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreGameOpen, setIsPreGameOpen] = useState<boolean>(true);
  const [isPlayerStep, setIsPlayerStep] = useState<boolean>(true);
  const [isBattleInited, setBattleInited] = useState(false);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [isRevive, setIsRevive] = useState<boolean>(false);
  const [roundReward, setRoundReward] = useState<number | undefined>();
  const [playDeckReward, setPlayDeckReward] = useState<number | undefined>();
  const [amountOfDamage, setAmountOfDamage] = useState<string | undefined>();
  const [softProfit, setSoftProfit] = useState<number>(0);
  const [playDeckProfit, setPlayDeckProfit] = useState<number>(0);

  const [currentRound, setCurrentRound] = useState<number>(1);
  const [enemyHp, setEnemyHp] = useState(
    getFormula(getCurrentFormula(currentRound, configS?.config?.enemyHPFormula), [
      {
        key: 'r',
        value: currentRound,
      },
    ]),
  );
  const [playerHp, setPlayerHp] = useState(
    getFormula(getCurrentFormula(gameS?.gameInfo?.hpGrade, configS?.config?.playerHPFormula), [
      {
        key: 'N',
        value: gameS?.gameInfo?.hpGrade,
      },
    ]),
  );
  const [tapsCount, setTapsCount] = useState<number>(0);

  useEffect(() => {
    loadScene(sceneForRoute[pathname]);
  }, [pathname]);

  function handleStartButton() {
    if (isGameOver) {
      /*if (!gameS?.gameInfo?.tutorialPassed) {
        gameS?.completeTutorial();
      }*/

      /*if (gameS?.gameInfo?.tutorialPassed) {
        gameS?.dropTutorial();
      }*/

      loadScene(LoadSceneEnum.Empty);
      setIsGameOver(false);
      return;
    }

    setIsLoading(true);
    resetStatesForUi();
    loadScene(LoadSceneEnum.BattleScene);

    /* gameS?.startBattle(currentRound).then(() => {
       const { status, errorData } = gameS?.request?.startBattle?.result;

       if (status) {
         sendMessage('ReactEventsHandler', ReactActions.StartBattle, currentRound);
         setIsPreGameOpen(false);
       } else {
         toast.success(errorData?.detail, {
           toastId: 'error-start-battle',
           closeButton: false,
           icon: false,
           progress: undefined,
         });
       }
     });*/
  }

  const restartBattle = () => {
    sendMessage('ReactEventsHandler', ReactActions.RestartBattle);
  };

  const handleUpdateHP = (isPlayer: any, score: React.SetStateAction<number>) => {
    if (isPlayer) {
      setPlayerHp(score);
    } else {
      setEnemyHp(score);
      if (score <= 0) {
        gameS?.nextRound().then((res) => {
          if (res) {
            continueBattle();
            setRoundReward(res.roundReward);
            setSoftProfit((prev) => {
              return prev + res.roundReward;
            });

            if (res.playDeckReward) {
              setPlayDeckReward(res.playDeckReward);
              setPlayDeckProfit((prev) => {
                return prev + res.playDeckReward;
              });
            }
          }
        });
      }
    }
  };

  const continueBattleAfterRevive = () => {
    setIsRevive(false);
    restartBattle();
  };

  const continueBattle = () => {
    sendMessage('ReactEventsHandler', ReactActions.ContinueBattle);
  };

  const battleData = () => {
    return {
      ...gameS?.gameInfo,
      ...configS?.config,
      tapMaxAttackCount: getFormula(configS?.config?.tapMaxAttackCountFormula, [
        {
          key: 'Y',
          value: gameS?.gameInfo?.tapAttackGrade,
        },
      ]),
      tapMaxDefenseCount: getFormula(configS?.config?.tapMaxDefenseCountFormula, [
        {
          key: 'Y',
          value: gameS?.gameInfo?.tapDefenseGrade,
        },
      ]),
    };
  };

  const pvpData = (): PvpInitData => {
    return {
      newRoundDelay: 6,
      playerID: gameS?.playDeckProfile?.telegramId.toString(),
      token: tokenS?.token,
      url: process.env.REACT_APP_API_URL,
      preparePhaseTime: configS?.config?.preparePhaseTime,
      armorGrade: gameS?.gameInfo?.armorGrade,
      readyTime: configS?.config?.readyTime,
      seachOpponentTimer: configS?.config?.seachOpponentTimer,
      weaponGrade: gameS?.gameInfo?.weaponGrade,
    };
  };

  const resetStatesForUi = () => {
    setCurrentRound(1);
    setBattleInited(false);
    setIsPreGameOpen(true);
    setIsPlayerStep(true);
    setSoftProfit(0);
    setPlayDeckProfit(0);
    setIsGameOver(false);
    setIsRevive(false);
  };

  // TODO: rework
  const handleSceneLoaded = (index: number) => {
    switch (index) {
      case SceneTypeEnum.Empty:
        resetStatesForUi();

        if (ProjectRoutes.Upgrade === pathname) {
          loadScene(LoadSceneEnum.MergeScene);
        }

        if (ProjectRoutes.Pvp === pathname) {
          loadScene(LoadSceneEnum.PvpScene);
        }

        break;
      case SceneTypeEnum.BattleScene:
        resetStatesForUi();
        if (!gameS?.gameInfo) {
          gameS?.fetchGameInfo().then(() => {
            sendMessage(
              'ReactEventsHandler',
              ReactActions.SetBattleData,
              JSON.stringify(battleData()),
            );
          });
        } else {
          sendMessage(
            'ReactEventsHandler',
            ReactActions.SetBattleData,
            JSON.stringify(battleData()),
          );
        }
        break;
      case SceneTypeEnum.MergeScene:
        loadModel(
          ModelTypeEnum.WEAPON,
          gameS?.gameInfo?.weaponGrade || 0,
          gameS?.gameInfo?.weaponPurchased || false,
        );
        break;
      case SceneTypeEnum.PvpScene:
        sendMessage('ReactEventsHandler', ReactActions.SetPvPData, JSON.stringify(pvpData()));
        break;
    }
    setIsSceneLoaded(true);
  };

  const handleBattleInited = () => {
    gameS?.startBattle().then(() => {
      const { status, errorData, data } = gameS?.request?.startBattle?.result;

      if (status && data) {
        setCurrentRound(data.startRound);
        sendMessage('ReactEventsHandler', ReactActions.StartBattle, data.startRound);
        setIsPreGameOpen(false);
        setIsLoading(false);
        setIsShowCombatButton(false);
        setBattleInited(true);
      } else {
        toast.success(errorData?.detail, {
          toastId: 'error-start-battle',
          closeButton: false,
          icon: false,
          progress: undefined,
        });
      }
    });
  };

  const handleUpdateRound = (round: number) => {
    console.log(round, ' round, react side');
    setRoundReward(undefined);
    setPlayDeckReward(undefined);
    setCurrentRound(round);
  };

  const handleFloatingText = (x: number, y: number, text: string) => {
    const indexOfN = text.indexOf('=');
    const indexOfMinus = text.lastIndexOf('-');
    setAmountOfDamage(
      indexOfN > 0 ? text.slice(indexOfMinus) : parseFloat(text).toFixed(1).toString(),
    );
  };

  const handleAttackStarted = (isPlayerAttack: boolean) => {
    setTapsCount(0);
    setAmountOfDamage(undefined);
    setIsPlayerStep(isPlayerAttack);
    setIsShowCombatButton(true);
  };

  const handleAttackEnded = () => {
    setIsShowCombatButton(false);
  };

  const handleGameOver = () => {
    setIsRevive(true);
  };

  const onGameOver = () => {
    setIsRevive(false);
    gameS.fetchBattleResult(Boolean(playDeckProfit)).then(() => {
      setIsGameOver(true);
    });
  };

  const loadModel = (type: ModelTypeEnum, grade: number, isPurchased: boolean) => {
    sendMessage(
      'ReactEventsHandler',
      ModelTypesActions[type],
      JSON.stringify({
        grade,
        isPurchased,
      }),
    );
  };

  const purchaseItem = () => {
    sendMessage('ReactEventsHandler', ReactActions.NewGradePurchased);
  };

  const setUnityTimeScale = (timescale: 1 | 0) => {
    sendMessage('ReactEventsHandler', ReactActions.SetTimeScale, timescale);
  };

  const handleNewTap = () => {
    setTapsCount((prev) => prev + 1);
  };

  useEffect(() => {
    // @ts-ignore
    addEventListener(UnityCallbacks.OnUpdateHP, handleUpdateHP);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnFloatingText, handleFloatingText);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnSceneLoaded, handleSceneLoaded);
    addEventListener(UnityCallbacks.OnBattleInited, handleBattleInited);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnUpdateRound, handleUpdateRound);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnNewAttackStarted, handleAttackStarted);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnAttackEnded, handleAttackEnded);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnGameOver, handleGameOver);
    // @ts-ignore
    addEventListener(UnityCallbacks.OnNewTap, handleNewTap);

    return () => {
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnUpdateHP, handleUpdateHP);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnFloatingText, handleFloatingText);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnSceneLoaded, handleSceneLoaded);
      removeEventListener(UnityCallbacks.OnBattleInited, handleBattleInited);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnUpdateRound, handleUpdateRound);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnNewAttackStarted, handleAttackStarted);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnAttackEnded, handleAttackEnded);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnGameOver, handleGameOver);
      // @ts-ignore
      removeEventListener(UnityCallbacks.OnNewTap, handleNewTap);
    };
  });

  return (
    <>
      {pathname === ProjectRoutes.Combat && (
        <CombatUi
          isShowCombatButton={isShowCombatButton}
          softProfit={softProfit}
          playDeckProfit={playDeckProfit}
          reward={roundReward}
          playDeckReward={playDeckReward}
          setRound={setCurrentRound}
          amountOfDamage={amountOfDamage}
          playerHp={playerHp}
          enemyHp={enemyHp}
          round={currentRound}
          isRevive={isRevive}
          isGameOver={isGameOver}
          isPreGameOpen={isPreGameOpen}
          isBattleInitialized={isBattleInited}
          isPlayerStep={isPlayerStep}
          tapsCount={tapsCount}
          onStartBattleClick={handleStartButton}
          onSetTimeScale={setUnityTimeScale}
          onContinueBattle={continueBattleAfterRevive}
          onGameOver={onGameOver}
        />
      )}
      {isLoading && (
        <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, right: 0, bottom: 0 }}>
          <PvpLoading />
        </div>
      )}
      {pathname === ProjectRoutes.Upgrade && (
        <UpgradeUi onLoadModel={loadModel} onPurchase={purchaseItem} />
      )}
    </>
  );
});
