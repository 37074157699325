export enum SceneTypeEnum {
  Empty = 1,
  BattleScene = 2,
  MergeScene = 3,
  PvpScene = 4,
}

export enum LoadSceneEnum {
  Empty = 'LoadEmptyScene',
  BattleScene = 'LoadBattleScene',
  MergeScene = 'LoadMergeScene',
  PvpScene = 'LoadPvPScene',
}
