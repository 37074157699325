import './SearchOpponent.css';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks/useStore';

const data = ['Looking for a match', 'Creating a room', 'Waiting for an opponent'];

interface IProps {
  onCancel: () => void;
}

export const SearchOpponent = observer((props: IProps) => {
  const { onCancel } = props;
  const { configS } = useStores();
  const [time, setTime] = useState<number>(0);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        return time + 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const getText = () => {
    if (time < configS?.config?.seachOpponentTimer) {
      return data[0];
    } else if (time < 20) {
      return data[1];
    } else {
      return data[2];
    }
  };

  const renderTimer = () => {
    const minutes = (~~(time / 60)).toString();
    const seconds = (time % 60).toString();

    return `${minutes}:${seconds.length > 1 ? seconds : 0 + seconds}`;
  };

  return (
    <div className='search-opponent-ui'>
      <div className='search-loading'>
        <span>{renderTimer()}</span>
      </div>
      <span
        className='search-title'
        style={{ marginBottom: time > configS?.config?.seachOpponentTimer ? 0 : '66px' }}
      >
        {getText()}
      </span>
      <button
        style={{ display: time > configS?.config?.seachOpponentTimer ? 'initial' : 'none' }}
        className='search-cancel-button'
        onClick={onCancel}
      >
        <span>CANCEL</span>
      </button>
    </div>
  );
});
