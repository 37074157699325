import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import * as jose from 'jose';
import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';

type Props = {
  webAppInitData?: string;
};

export default class TokenStore {
  constructor({ webAppInitData }: Props) {
    makeAutoObservable(this);
    // this.getFakeAuth(webAppInitData);
    // this.validateCookie();
    if (!this.isAuth && webAppInitData) {
      this.auth(webAppInitData);
    } else {
      this.setIsLoaded(true);
    }
  }

  _token?: string;

  get token(): typeof this._token {
    return this._token;
  }

  _error?: AxiosResponse<object>;

  get error(): typeof this._error {
    return this._error;
  }

  private _isAuth: boolean = false;

  get isAuth(): typeof this._isAuth {
    return this._isAuth;
  }

  private _isLoaded: boolean = false;

  get isLoaded(): typeof this._isLoaded {
    return this._isLoaded;
  }

  setError = (value?: typeof this._error) => {
    this._error = value;
  };

  public getFakeAuth = (data?: string) => {
    console.log('__________________________');
    console.log(data, ' NOW USED FAKE AUTH');
    console.log('__________________________');
    this.setToken(
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjU0MzQ1NDUwLCJsb2NhbGUiOiJlbiIsInNlc3Npb25faWQiOiJkMWI4NTJmOS00NjhmLTQ5NTctYjUwNC01ZjhmODU3OWM5N2UiLCJpYXQiOjE3MjcyNTc2MjQsImV4cCI6MTcyNzMwMDgyNH0.zzNtdr4hHprsKe5eUdMAUB4Wntz-97TJqww_HHqkn9Y',
    );
    this.setIsAuth(true);
    this.setIsLoaded(true);
  };

  setToken = (access_token?: string) => {
    this._token = access_token;
  };

  private setIsLoaded = (value: typeof this._isLoaded) => {
    this._isLoaded = value;
  };

  private setIsAuth = (value: typeof this._isAuth) => {
    this._isAuth = value;
  };

  private auth(webAppInitData?: string): Promise<
    AxiosResponse<{
      access_token: string;
    }>
  > {
    const promise: Promise<AxiosResponse<{ access_token: string }>> = axios.post(
      `${process.env.REACT_APP_API_URL}/auth/authorize`,
      { webapp_data: webAppInitData },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    promise
      .then(({ status, data }: AxiosResponse<{ access_token: string }>) => {
        this.setIsAuth([200, 201].includes(status));
        if (this.isAuth) {
          this.setToken(data['access_token']);
          // Установка куки на 30 минут
          // Cookies.set('access_token', data['access_token'], { expires: new Date(new Date().getTime() + 30 * 60 * 1000) });
        }
      })
      .catch(({ response }: AxiosError<object>) => {
        this.setIsAuth(false);
        this.setError(response);
        console.log('Authentication failed, error: ' + response?.data);
      })
      .finally(() => this.setIsLoaded(true));
    return promise;
  }

  private validateCookie = (): void => {
    const accessToken: string | undefined = Cookies.get('access_token');
    if (accessToken) {
      try {
        const jwtAccessToken = jose.decodeJwt(accessToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (currentTimestamp > jwtAccessToken?.exp!) {
          Cookies.remove('access_token');
          return;
        }
      } catch (e) {
        Cookies.remove('access_token');
        return;
      }
      this.setToken(accessToken);
      this.setIsAuth(true);
      this.setIsLoaded(true);
    }
  };
}
