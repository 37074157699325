export const UnityCallbacks = {
  OnUpdateHP: 'OnUpdateHP',
  OnFloatingText: 'OnFloatingText',
  OnSceneLoaded: 'OnSceneLoaded',
  OnBattleInited: 'OnBattleInited',
  OnUpdateRound: 'OnUpdateRound',
  OnNewAttackStarted: 'OnNewAttackStarted',
  OnAttackEnded: 'OnAttackEnded',
  OnGameOver: 'OnGameOver',
  OnNewTap: 'OnNewTap',
  // pvp
  OnPvPGameStateChanged: 'OnPvPGameStateChanged',
  OnPvPRoundChanged: 'OnPvPRoundChanged',
  OnPvPBattleEnd: 'OnPvPBattleEnd',
  OnPvPRoundEnd: 'OnPvPRoundEnd',
  OnPvPReadyTimerChanged: 'OnPvPReadyTimerChanged',
  OnPvPPreparePhaseTimerChanged: 'OnPvPPreparePhaseTimerChanged',
  OnPvPSearchEnd: 'OnPvPSearchEnd',
  OnPvPGameReady: 'OnPvPGameReady',
  OnPvPNetworkError: 'OnPvPNetworkError',
};

export const ReactActions = {
  LoadArmor: 'LoadArmor',
  LoadWeapon: 'LoadWeapon',
  StartBattle: 'StartBattle',
  SetBattleData: 'SetBattleData',
  NewGradePurchased: 'NewGradePurchased',
  SetTimeScale: 'SetTimeScale',
  ContinueBattle: 'ContinueBattle',
  RestartBattle: 'RestartBattle',
  SetAdressablesRemoteURL: 'SetAdressablesRemoteURL',
  // pvp
  SetPvPData: 'SetPvPData',
  PvPSignSet: 'PvPSignSet',
  PvPSearchStart: 'PvPSearchStart',
  PvPSearchCancel: 'PvPSearchCancel',
};
