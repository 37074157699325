import type { AxiosInstance } from 'axios';
import { makeAutoObservable } from 'mobx';
import type { Config } from '../models/config';
import { RequestStore } from './RequestStore';

type Props = {
  axiosInstance: AxiosInstance;
};

const defaultConfig: Config = {
  PDC_ACHIEVEMENTS: 0,
  PDC_INVITE_FRIEND: 0,
  PDC_INVITE_PREMIUM_FRIEND: 0,
  PDC_TASKS: 0,
  attackTimer: 1,
  defenceTimer: 1,
  knockdownThresholdPersent: 1,
  enemyHPFormula: [],
  enemyStrengthPerRoundKf: 1,
  enemyDamageMultiplier: 1,
  enemyHPMultiplier: 1,
  weaponGradeMultiplier: 1,
  armorGradeMultiplier: 1,
  priceUpgradeTap: [],
  battleRewardFormula: '',
  battleScoreCoefficient: [],
  clothArmorFormula: [],
  clothUpgradeFormula: [],
  dmgOnPlayerFormula: '',
  enemyDamageFormula: [],
  payResurrectionFormula: '',
  playerDamageFormula: [],
  playerHPFormula: [],
  skillsUpgradeCost: 1,
  skillsUpgradeIncrease: 1,
  skillsUpgradeInsert: 1,
  tapAttackFormula: [],
  test: 1,
  tapDefFormula: [],
  priceUpgradeEquip: [],
  refReward: 0,
  refTgPremReward: 0,
  tapMaxAttackCountFormula: '',
  tapMaxDefenseCountFormula: '',
  tapMaxCount: 0,
  preparePhaseTime: 0,
  readyTime: 0,
  seachOpponentTimer: 0,
  autofarm_BaseRatePerMinute: 0,
  autofarm_maxAccumulation: 0,
  autofarm_RatePerMinuteFormula: '',
  autofarm_subMultiplier: 0,
  autofarmLevelConfig: [],
};

export class ConfigStore {
  request;
  private readonly client: AxiosInstance;

  public constructor(props: Props) {
    this.client = props.axiosInstance;
    this.request = {
      getConfig: new RequestStore<Config>({
        client: this.client,
        url: '/config/get_config',
        method: 'get',
      }),
    };
    makeAutoObservable(this);
  }

  private _config: Config = defaultConfig;

  public get config(): typeof this._config {
    return this._config;
  }

  public async fetchConfig() {
    await this.request.getConfig.request().then(() => {
      const { data, status } = this.request.getConfig.result;
      if (!status || !data) {
        this.setConfig(defaultConfig);
      } else {
        this.setConfig(data);
      }
      return true;
    });
  }

  private setConfig(value: typeof this._config) {
    this._config = value;
  }
}
