import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const PvpPlayerHealthBarSvg = () => (
  <svg width='154' height='24' viewBox='0 0 154 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.5'
      d='M0 5.00001C0 2.23859 2.23857 1.40178e-05 5 1.39119e-05C53 1.20701e-05 101 -1.49269e-05 149 1.16141e-05C151.761 1.31409e-05 154 2.23859 154 5.00001V7.17159C154 7.70202 153.789 8.21073 153.414 8.5858L139.757 22.2427C138.632 23.3679 137.106 24 135.515 24C92.3417 24 48.2415 24 4.99959 24C2.23817 24 0 21.7614 0 19V5.00001Z'
      fill='black'
    />
    <rect x='2.5' y='2.5' width='45' height='19' rx='3.5' stroke='#333333' />
    <rect x='50.5' y='2.5' width='45' height='19' rx='3.5' stroke='#333333' />
    <g className='third' filter='url(#filter0_i_495_19986)'>
      <rect x='2' y='2' width='46' height='20' rx='4' fill='#19C8FA' />
    </g>
    <g className='second' filter='url(#filter1_i_495_19986)'>
      <rect x='50' y='2' width='46' height='20' rx='4' fill='#19C8FA' />
    </g>
    <g className='first' filter='url(#filter2_i_495_19986)'>
      <path
        d='M102 22H134.515C136.106 22 137.632 21.3679 138.757 20.2426L151.414 7.58579C151.789 7.21071 152 6.70201 152 6.17157V4C152 2.89543 151.105 2 150 2H102C99.7909 2 98 3.79086 98 6V18C98 20.2091 99.7909 22 102 22Z'
        fill='#19C8FA'
      />
    </g>
    <defs>
      <filter
        id='filter0_i_495_19986'
        x='2'
        y='2'
        width='46'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19986'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19986' />
      </filter>
      <filter
        id='filter1_i_495_19986'
        x='50'
        y='2'
        width='46'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19986'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19986' />
      </filter>
      <filter
        id='filter2_i_495_19986'
        x='98'
        y='2'
        width='54'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19986'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19986' />
      </filter>
    </defs>
  </svg>
);

export const PvpPlayerHealthBar = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PvpPlayerHealthBarSvg} {...props} />
);
