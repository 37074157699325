export enum PvpChoice {
  Rock,
  Scissors,
  Paper,
  None,
}

export enum GameState {
  Waiting,
  Ready,
  PreparePhase,
  ReleasePhase,
  None,
}

export interface PvpInitData {
  newRoundDelay?: number;
  playerID?: string;
  token?: string;
  url?: string;
  armorGrade?: number;
  weaponGrade?: number;
  preparePhaseTime?: number;
  readyTime?: number;
  seachOpponentTimer?: number;
}

export interface PvpRoundData {
  enemySign: PvpChoice;
  round: number;
  playerWins: number;
  enemyWins: number;
}

export enum BattleResultEnum {
  LOSS = 'LOSS',
  WIN = 'WIN',
  REFUND = 'REFUND',
}

export enum RoundResultEnum {
  WIN = 'YOU WIN!',
  LOSE = 'YOU LOSE!',
  DRAW = 'DRAW!',
  NONE = 'NONE',
}
