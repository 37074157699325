import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const ActiveBetSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <g clipPath='url(#clip0_327_28708)'>
      <circle cx='10' cy='10' r='9.5' stroke='#F6BE30' />
      <circle cx='10' cy='10' r='5' fill='#F6BE30' />
    </g>
    <defs>
      <clipPath id='clip0_327_28708'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const ActiveBetIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ActiveBetSvg} {...props} />
);
