import './UserLeaguePlaceItem.css';
import { getFio } from '../../../utils/users';

interface IProps {
  battleScore: number;
  firstName: string;
  lastName: string;
  kills: number;
  place: number;
  isUser?: boolean;
}

const medalImages: Record<number, string> = {
  1: '/assets/league/medals/medal-one.svg',
  2: '/assets/league/medals/medal-two.svg',
  3: '/assets/league/medals/medal-three.svg',
};

export const UserLeaguePlaceItem = (props: IProps) => {
  const { firstName, lastName, battleScore, isUser, place, kills } = props;

  const renderMedal = () => {
    if (isUser) {
      return <></>;
    }

    if (place <= 3) {
      return <img src={medalImages[place]} alt='medal-icon' className='medal-icon' />;
    }

    return (
      <div
        className='medal-icon'
        style={{ border: `1px solid rgba(255,255,255,0.1)`, borderRadius: '50%' }}
      >
        <span>{place}</span>
      </div>
    );
  };

  return (
    <div
      className='user-place-container'
      style={{ borderRadius: isUser ? '10px' : 0, margin: isUser ? '0 17px' : 0 }}
    >
      {renderMedal()}
      <div className='user-info'>
        <span className='user-name'>
          {isUser ? `Your place: ${place}` : getFio(lastName, firstName)}
        </span>
        <div className='user-stats'>
          <span className='user-stat-text'>{`BS: ${Math.round(battleScore)}`}</span>
          <span>|</span>
          <span className='user-stat-text'>{`KLS: ${kills}`}</span>
        </div>
      </div>
    </div>
  );
};
