import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const RightArrowSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M4.42918 3.97909C3.67243 3.25061 3.67309 2.03903 4.43063 1.31137C5.14681 0.623448 6.27829 0.623448 6.99447 1.31137L12.1614 6.27448C13.1416 7.21602 13.1416 8.78398 12.1614 9.72552L6.99447 14.6886C6.27829 15.3766 5.14681 15.3766 4.43063 14.6886C3.67309 13.961 3.67243 12.7494 4.42918 12.0209L8.59741 8.00835C8.59952 8.00631 8.59995 8.00528 8.60011 8.00487L8.60013 8.00484C8.60049 8.00396 8.60096 8.00229 8.60096 8C8.60096 7.99771 8.60049 7.99604 8.60013 7.99516L8.60011 7.99512C8.59994 7.99472 8.59952 7.99368 8.59741 7.99165L4.42918 3.97909Z'
      fill='white'
      stroke='black'
      strokeWidth='1.32696'
    />
  </svg>
);

export const RightArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RightArrowSvg} {...props} />
);
