import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import './HomeUi.css';
import { useNavigate } from 'react-router';
import { ProjectRoutes } from '../../const/ProjectRoutes';
import { useStores } from '../../hooks/useStore';
import { coinImages, PriceEnum, ShopItemEnum } from '../../types/shop';
import { getFormula } from '../../utils/formulas';
import { convertServerTimeToClientTime } from '../../utils/time';
import { Currency } from '../Currency';
import { RightArrowIcon } from '../icons/RightArrowIcon';
import { ReferralUi } from '../ReferralUi';
import { Tabs } from '../Tabs';
import { Timer } from '../Timer';

const tabs: string[] = ['autoFarm', 'friends'];

export const HomeUi = observer(() => {
  const { configS, gameS } = useStores();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  // const webApp = useWebApp();

  useEffect(() => {
    gameS?.getUserSubs();
    gameS?.getAutoFarmDelayerReward();
  }, []);

  const hasTimer = useMemo(() => {
    return gameS?.userSubs?.find((x) => x.shopItem.itemType === ShopItemEnum.AUTO_FIGHT_SUB);
  }, [gameS?.userSubs]);

  const perMinute = useMemo(() => {
    if (gameS?.gameInfo?.maxRound >= 10) {
      const k =
        configS?.config?.autofarmLevelConfig?.find(
          (x) => x.level === gameS?.gameInfo?.autofarmGrade,
        )?.value || 1;

      const result = (
        getFormula(configS?.config?.autofarm_RatePerMinuteFormula, [
          {
            key: 'n',
            value: gameS?.gameInfo?.maxRound,
          },
        ]) *
        k *
        (hasTimer ? configS?.config?.autofarm_subMultiplier : 1)
      ).toFixed(10);

      return parseFloat(result);
    }
    return configS?.config?.autofarm_BaseRatePerMinute;
  }, [gameS?.gameInfo?.maxRound, gameS?.gameInfo?.autofarmGrade, hasTimer]);

  const maxReward = useMemo(() => {
    return Math.round(perMinute * 60 * configS?.config?.autofarm_maxAccumulation);
  }, [perMinute]);

  const perSecond = useMemo(() => {
    return perMinute / 60;
  }, [perMinute]);

  const reward = useMemo(() => {
    return gameS?.gameInfo?.delayedReward;
  }, [gameS?.gameInfo?.delayedReward]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (gameS?.gameInfo) {
        if (gameS?.gameInfo?.delayedReward < maxReward) {
          gameS?.setGameInfo({
            ...gameS?.gameInfo,
            delayedReward: parseFloat((gameS?.gameInfo?.delayedReward + perSecond)?.toFixed(1)),
          });
        } else {
          gameS?.setGameInfo({ ...gameS?.gameInfo, delayedReward: Number(maxReward) });
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [gameS?.gameInfo]);

  const onUpgradeButtonClick = () => {};
  const onClaimRewardClick = () => {
    gameS?.claimAutoFightReward();
  };
  const onAirDropClick = () => {};

  useEffect(() => {
    const timer = setInterval(() => {
      if (gameS?.gameInfo && gameS?.gameInfo?.autofarmClaimed) {
        const initialStartTime = convertServerTimeToClientTime(gameS?.gameInfo?.autofarmClaimed).getTime();
        const currentTime = new Date().getTime();
        const timeElapsed = Math.floor((currentTime - initialStartTime) / 1000);
        const remainingTime = 60 - timeElapsed;

        if (remainingTime > 0) {
          setClaimTimer(remainingTime);
        } else {
          setClaimTimer(0); // Время истекло
        }
      } else {
        setClaimTimer(0);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [gameS?.gameInfo?.autofarmClaimed]);

  const [claimTimer, setClaimTimer] = useState<number>(0);

  return (
    <div className='home-ui-layout'>
      <div className='home-ui-wrapper'>
        <Currency sticky />
        {activeTab === tabs[0] ? (
          <div className='home-ui-container'>
            <span>Welcome to punchout!</span>
            <div className='home-auto-farm-container'>
              <div className='auto-farm-stats'>
                <span>Autofarm</span>
                <span>{`Level: ${gameS?.gameInfo?.autofarmGrade}`}</span>
              </div>
              <div className='auto-farm-progress'>
                <div style={{ width: `${(reward / maxReward) * 100}%` }} />
              </div>
              <div className='auto-farm-stats'>
                <span
                  style={{ fontSize: '12px' }}
                >{`Per minute: ${parseFloat(perMinute?.toFixed(0))}`}</span>
                <span style={{ fontSize: '12px' }}>{`Max: ${maxReward.toFixed(0)}`}</span>
              </div>
              <div className='auto-farm-coins'>
                <div>
                  <img src={coinImages[PriceEnum.SOFT]} alt='coin-icon' />
                  <span>{gameS?.gameInfo?.delayedReward?.toFixed(0)}</span>
                </div>
                <button onClick={ !claimTimer ? onClaimRewardClick : undefined }
                        style={ { opacity: claimTimer ? 0.5 : 1 } }>
                  <span>{ !claimTimer ? `Claim` : `00:${ claimTimer < 10 ? '0' : '' }${ claimTimer }` }</span>
                </button>
              </div>
              <div className='auto-farm-buttons'>
                <button className='upgrade-button' onClick={onUpgradeButtonClick}>
                  <span>Upgrade</span>
                  <RightArrowIcon />
                </button>
                <div className='timer-container'>
                  <span
                    style={{
                      fontSize: '18px',
                      margin: '0 16px',
                    }}
                    onClick={() => {
                      if (!hasTimer) {
                        navigate(ProjectRoutes.Shop + `?item=${ShopItemEnum.AUTO_FIGHT_SUB}`);
                      }
                    }}
                  >
                    X2
                  </span>
                  <div />
                  <span
                    style={{
                      fontSize: '16px',
                      width: '100%',
                    }}
                  >
                    {hasTimer ? (
                      <Timer
                        date={hasTimer.activeUntil}
                        forAutoFight
                        onExpire={gameS?.getUserSubs}
                      />
                    ) : (
                      `0:00`
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className='line' />
            <button style={{ opacity: 0.5 }} onClick={onAirDropClick}>
              <span>About Airdrop & Listing</span>
            </button>
          </div>
        ) : (
          <ReferralUi />
        )}
      </div>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        updateActiveTab={setActiveTab}
        imageName='ui_home_tabs_block_0'
      />
    </div>
  );
});
