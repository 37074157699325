import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const PvpEnemyHealthBarSvg = () => (
  <svg width='154' height='24' viewBox='0 0 154 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      opacity='0.5'
      d='M154 5.00001C154 2.23859 151.761 1.40178e-05 149 1.39119e-05C101 1.20701e-05 53 -1.49269e-05 5 1.16141e-05C2.23857 1.31409e-05 0 2.23859 0 5.00001V7.17159C0 7.70202 0.210714 8.21073 0.585786 8.5858L14.2426 22.2427C15.3679 23.3679 16.894 24 18.4853 24C61.6583 24 105.758 24 149 24C151.762 24 154 21.7614 154 19V5.00001Z'
      fill='black'
    />
    <rect
      x='-0.5'
      y='0.5'
      width='45'
      height='19'
      rx='3.5'
      transform='matrix(-1 0 0 1 151 2)'
      stroke='#777777'
    />
    <rect
      x='-0.5'
      y='0.5'
      width='45'
      height='19'
      rx='3.5'
      transform='matrix(-1 0 0 1 103 2)'
      stroke='#777777'
    />
    <g className='third' filter='url(#filter0_i_495_19997)'>
      <rect width='46' height='20' rx='4' transform='matrix(-1 0 0 1 152 2)' fill='#F6BE30' />
    </g>
    <g className='second' filter='url(#filter1_i_495_19997)'>
      <rect width='46' height='20' rx='4' transform='matrix(-1 0 0 1 104 2)' fill='#F6BE30' />
    </g>
    <g className='first' filter='url(#filter2_i_495_19997)'>
      <path
        d='M52 22H19.4853C17.894 22 16.3679 21.3679 15.2426 20.2426L2.58578 7.58579C2.21071 7.21071 2 6.70201 2 6.17157V4C2 2.89543 2.89543 2 4 2H52C54.2091 2 56 3.79086 56 6V18C56 20.2091 54.2091 22 52 22Z'
        fill='#F6BE30'
      />
    </g>
    <defs>
      <filter
        id='filter0_i_495_19997'
        x='106'
        y='2'
        width='46'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19997'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19997' />
      </filter>
      <filter
        id='filter1_i_495_19997'
        x='58'
        y='2'
        width='46'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19997'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19997' />
      </filter>
      <filter
        id='filter2_i_495_19997'
        x='2'
        y='2'
        width='54'
        height='20'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology
          radius='1'
          operator='erode'
          in='SourceAlpha'
          result='effect1_innerShadow_495_19997'
        />
        <feOffset />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
        <feColorMatrix type='matrix' values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='shape' result='effect1_innerShadow_495_19997' />
      </filter>
    </defs>
  </svg>
);

export const PvpEnemyHealthBar = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PvpEnemyHealthBarSvg} {...props} />
);
