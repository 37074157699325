import './Bet.css';
import type { PriceEnum } from '../../../../../types/shop';
import { coinImages } from '../../../../../types/shop';
import { ActiveBetIcon } from '../../../../icons/ActiveBetIcon';
import { BetIcon } from '../../../../icons/BetIcon';
import { BlueArrowIcon } from '../../../../icons/BlueArrowIcon';

export interface IBet {
  id: number;
  isTutorial?: boolean;
  bet?: number;
  coinType?: PriceEnum;
}

interface IProps extends IBet {
  isActive: boolean;
  onChooseBet: (bet: IBet | undefined) => void;
}

export const Bet = (props: IProps) => {
  const { isTutorial, onChooseBet, bet, isActive, coinType, id } = props;

  return (
    <div
      className='bet-container'
      style={{
        border: isActive ? '1px solid #F6BE30' : '1px solid transparent',
        justifyContent: isTutorial ? 'inherit' : 'space-between',
      }}
      onClick={() =>
        onChooseBet({
          id,
          bet,
          coinType,
          isTutorial,
        })
      }
    >
      {isActive ? <ActiveBetIcon /> : <BetIcon />}
      {isTutorial ? (
        <span>Tutorial fight</span>
      ) : (
        <>
          <div className='bet-info'>
            <span>BET:</span>
            <span>{bet}</span>
            <img alt='coin-icon' src={coinType ? coinImages[coinType] : ''} />
          </div>
          <BlueArrowIcon />
          <div className='bet-info'>
            <span>WIN:</span>
            <span>{bet ? bet * 2 : 0}</span>
            <img alt='coin-icon' src={coinType ? coinImages[coinType] : ''} />
          </div>
        </>
      )}
    </div>
  );
};
