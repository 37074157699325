import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import { useStores } from '../../hooks/useStore';
import { coinImages, PriceEnum } from '../../types/shop';
import { SkillType } from '../../types/skills';
import {
  getCurrentFormula,
  getFormula,
  getSkillUpgradeCost,
  numberFormatter,
} from '../../utils/formulas';
import { Currency } from '../Currency';
import './UpgradeUi.css';
import { UpgradeArrowIcon } from '../icons/UpgradeArrowIcon';
import type { TScoreItem } from '../ScoreItem';
import { ScoreItem } from '../ScoreItem';
// eslint-disable-next-line import/order
import { ModelTypeEnum } from '../../types/upgrade';
// eslint-disable-next-line import/order
import { Tabs } from '../Tabs';
import { SkillItem, StatItem } from '../Upgrade';

const tabs: string[] = ['weapon', 'armor', 'boost'];

interface IProps {
  onPurchase: () => void;
  onLoadModel: (type: ModelTypeEnum, grade: number, isPurchased: boolean) => void;
}

export const UpgradeUi = observer((props: IProps) => {
  const { onLoadModel } = props;
  const { gameS, configS } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(searchParams.get('type') || 'weapon');
  const getPrice = (grade: number | undefined) => {
    if (!grade) {
      return grade;
    }

    return getFormula(getCurrentFormula(grade, configS?.config?.clothUpgradeFormula), [
      {
        key: 'n',
        value: grade + 1,
      },
    ]);
  };

  const updateActiveTab = (tab: string) => {
    setActiveTab(tab);
    setSearchParams((searchParams) => {
      searchParams.set('type', tab);
      return searchParams;
    });

    if (tab === 'weapon') {
      onLoadModel(
        ModelTypeEnum.WEAPON,
        gameS?.gameInfo?.weaponGrade || 0,
        gameS?.gameInfo?.weaponPurchased || false,
      );
    }

    if (tab === 'armor') {
      onLoadModel(
        ModelTypeEnum.ARMOR,
        gameS?.gameInfo?.armorGrade || 0,
        gameS?.gameInfo?.armorPurchased || false,
      );
    }
  };

  const scores: TScoreItem[] = useMemo(() => {
    return [
      {
        title: 'HP',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.hpGrade, configS?.config?.playerHPFormula),
          [
            {
              key: 'N',
              value: gameS?.gameInfo?.hpGrade,
            },
          ],
        ),
      },
      {
        title: 'Damage',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
          [
            {
              key: 'n',
              value: gameS?.gameInfo?.weaponGrade || 1,
            },
            {
              key: 'MT',
              value: configS?.config?.tapMaxCount,
            },
          ],
        ),
      },
      {
        title: 'Armor',
        value: getFormula(
          getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
          [
            {
              key: 'n',
              value: gameS?.gameInfo?.armorGrade || 1,
            },
            {
              key: 'MT',
              value: configS?.config?.tapMaxCount,
            },
          ],
        ),
      },
      {
        title: 'TAP ATK',
        value: getFormula(configS?.config?.tapMaxAttackCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapAttackGrade,
          },
        ]),
      },
      {
        title: 'TAP DEF',
        value: getFormula(configS?.config?.tapMaxDefenseCountFormula, [
          {
            key: 'Y',
            value: gameS?.gameInfo?.tapDefenseGrade,
          },
        ]),
      },
    ];
  }, [gameS.gameInfo, configS.config]);

  const onBuyButtonClick = (type: ModelTypeEnum) => {
    gameS
      ?.buyModel(type)
      .then(() =>
        gameS
          ?.mergeItems(type)
          .then(() =>
            onLoadModel(
              type,
              (type === ModelTypeEnum.WEAPON
                ? gameS?.gameInfo?.weaponGrade
                : gameS?.gameInfo?.armorGrade) || 0,
              (type === ModelTypeEnum.WEAPON
                ? gameS?.gameInfo?.weaponPurchased
                : gameS?.gameInfo?.armorPurchased) || false,
            ),
          ),
      );
  };

  const renderTabs = () => {
    if (activeTab === 'weapon') {
      return (
        <>
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.weaponGrade || 1,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.weaponGrade || 1}
          />
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.weaponGrade, configS?.config?.playerDamageFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.weaponGrade ? gameS?.gameInfo?.weaponGrade + 1 : 2,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.weaponGrade || 2}
            newGrade
          />
        </>
      );
    }

    if (activeTab === 'armor') {
      return (
        <>
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.armorGrade || 1,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.armorGrade || 1}
            isArmor
          />
          <StatItem
            damage={getFormula(
              getCurrentFormula(gameS?.gameInfo?.armorGrade, configS?.config?.clothArmorFormula),
              [
                {
                  key: 'n',
                  value: gameS?.gameInfo?.armorGrade ? gameS?.gameInfo?.armorGrade + 1 : 2,
                },
                {
                  key: 'MT',
                  value: configS?.config?.tapMaxCount,
                },
              ],
            )}
            grade={gameS?.gameInfo?.armorGrade || 2}
            isArmor
            newGrade
          />
        </>
      );
    }

    return <></>;
  };

  const renderMergeButton = () => {
    const price = getPrice(
      activeTab === 'armor' ? gameS?.gameInfo?.armorGrade : gameS?.gameInfo?.weaponGrade,
    );
    const haveMoney = Boolean(gameS?.gameInfo?.softCoins && gameS?.gameInfo?.softCoins >= price);
    return (
      <button
        className='merge-button'
        style={{
          backgroundImage: haveMoney
            ? `url(./assets/ui_button_merge_act.svg)`
            : `url(./assets/ui_button_merge.svg)`,
        }}
        disabled={!haveMoney}
        onClick={() => {
          onBuyButtonClick(activeTab === 'armor' ? ModelTypeEnum.ARMOR : ModelTypeEnum.WEAPON);
        }}
      >
        <UpgradeArrowIcon />
        <span>Upgrade</span>
        <div
          style={{
            border: `2px solid ${haveMoney ? '#F6BE30' : '#CECECE'}`,
            background: `${haveMoney ? 'linear-gradient(0deg, #F6BE30 0%, #F6BE30 100%), rgba(0, 0, 0, 0.30)' : 'linear-gradient(0deg, #CECECE 0%, #CECECE 100%), rgba(0, 0, 0, 0.30)'}`,
          }}
        >
          <img src={coinImages[PriceEnum.SOFT]} alt='coin-icon' />
          <span>{numberFormatter(price)}</span>
        </div>
      </button>
    );
  };

  return (
    <div
      className='upgrade-overlay'
      style={{ backgroundColor: activeTab !== 'boost' ? 'transparent' : '#111138' }}
    >
      <div className='upgrade-header'>
        <Currency sticky />
        <div className='score-container'>
          {scores.map((score, index) => {
            return <ScoreItem key={index} {...score} />;
          })}
        </div>
        <div className='score-container' style={{ marginTop: '12px', justifyContent: 'center' }}>
          <ScoreItem
            {...{
              title: 'BATTLE SCORE',
              value: gameS?.gameInfo?.battlescore,
              battleScore: true,
            }}
          />
        </div>
        {activeTab === 'boost' && (
          <div className='skills-container'>
            <SkillItem
              maxGrade={10}
              grade={gameS?.gameInfo?.tapAttackGrade || 0}
              title='Attack Tap Power'
              img={'./assets/ui_icon_tapatk.png'}
              price={getSkillUpgradeCost(
                gameS?.gameInfo?.tapAttackGrade || 0,
                [configS?.config.skillsUpgradeCost],
                [configS?.config?.skillsUpgradeIncrease],
                configS?.config?.skillsUpgradeInsert,
              )}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.AttackSkill)}
            />
            <SkillItem
              maxGrade={10}
              grade={gameS?.gameInfo?.tapDefenseGrade || 0}
              title='Defence Tap Power'
              img={'./assets/ui_icon_tapdef.png'}
              price={getSkillUpgradeCost(
                gameS?.gameInfo?.tapDefenseGrade || 0,
                [configS?.config.skillsUpgradeCost],
                [configS?.config?.skillsUpgradeIncrease],
                configS?.config?.skillsUpgradeInsert,
              )}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.DefenceSkill)}
            />
            <SkillItem
              maxGrade={400}
              grade={gameS?.gameInfo?.hpGrade || 0}
              title='HP Boost'
              img={'./assets/ui_icon_boost_hp.png'}
              price={getPrice(gameS?.gameInfo?.hpGrade ? gameS?.gameInfo?.hpGrade : 0)}
              money={gameS?.gameInfo?.softCoins}
              onClick={() => gameS?.upgradeSkill(SkillType.HpSkill)}
            />
          </div>
        )}
      </div>
      <div className='upgrade-merge-container'>
        {activeTab !== 'boost' && (
          <div className='upgrade-merge'>
            {(activeTab === 'armor' || activeTab === 'weapon') && renderMergeButton()}
            <div className='stats'>{renderTabs()}</div>
          </div>
        )}
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
          imageName='ui_merge_tabs_block_0'
        />
      </div>
    </div>
  );
});
