import './PvpFightUi.css';
import React from 'react';
import { Typography } from 'antd';
import { useUnityLogicContext } from '../../../../context/UnityLogicProvider';
import { ReactActions } from '../../../../types/actions';
import { BattleResultEnum, GameState, PvpChoice } from '../../../../types/pvp';
import type { PriceEnum } from '../../../../types/shop';
import { coinImages } from '../../../../types/shop';
import { cn } from '../../../../utils/cn';
import { NoneIcon } from '../../../icons/NoneIcon';
import { PaperIcon } from '../../../icons/PaperIcon';
import { PvpEnemyHealthBar } from '../../../icons/PvpEnemyHealthBar';
import { PvpPlayerHealthBar } from '../../../icons/PvpPlayerHealthBar';
import { RockIcon } from '../../../icons/RockIcon';
import { ScissorsIcon } from '../../../icons/ScissorsIcon';
import type { IBet } from '../PvpBetUi/Bet';

interface IGameButton {
  type: PvpChoice;
  content: React.ReactNode;
}

const PvpSignIcons: Record<PvpChoice, React.ReactNode> = {
  [PvpChoice.Rock]: <RockIcon />,
  [PvpChoice.Paper]: <PaperIcon />,
  [PvpChoice.Scissors]: <ScissorsIcon />,
  [PvpChoice.None]: <NoneIcon />,
};

const data: IGameButton[] = [
  {
    type: PvpChoice.Rock,
    content: PvpSignIcons[PvpChoice.Rock],
  },
  {
    type: PvpChoice.Scissors,
    content: PvpSignIcons[PvpChoice.Scissors],
  },
  {
    type: PvpChoice.Paper,
    content: PvpSignIcons[PvpChoice.Paper],
  },
];

interface IProps {
  userName: string;
  bet?: IBet;
  onFinish: (coinType?: PriceEnum) => void;
}

const backgrounds: Record<BattleResultEnum, string> = {
  [BattleResultEnum.WIN]: 'linear-gradient(358deg, #19C8FA -61.48%, rgba(0, 0, 0, 0.00) 161.47%)',
  [BattleResultEnum.LOSS]: 'linear-gradient(358deg, #FD2222 -61.48%, rgba(0, 0, 0, 0.00) 161.47%)',
  [BattleResultEnum.REFUND]:
    'linear-gradient(358deg, #FAFAFA -61.48%, rgba(0, 0, 0, 0.00) 161.47%)',
};

const healthBarClasses: Record<number, string> = {
  0: 'three',
  1: 'two',
  2: 'one',
  3: 'zero',
};

export const PvpFightUi = (props: IProps) => {
  const {
    pvpRound,
    signType,
    setSignType,
    sendMessage,
    phaseTimer,
    battleWinner,
    roundData,
    roundResult,
    gameState,
    isPhaseStarted,
  } = useUnityLogicContext();
  const { onFinish, bet, userName } = props;

  const onSignChoice = (type: PvpChoice) => {
    console.log('USER CHOICE ' + type);
    sendMessage('ReactEventsHandler', ReactActions.PvPSignSet, type);
    setSignType(type);
  };

  const renderBattleResult = () => {
    if (roundData) {
      const result =
        bet && bet?.isTutorial
          ? BattleResultEnum.REFUND
          : roundData?.playerWins > roundData?.enemyWins
            ? BattleResultEnum.WIN
            : BattleResultEnum.LOSS;

      return (
        <div className='battle-result-overlay'>
          <span>BATTLE RESULT</span>
          <div className='battle-result-score'>
            <span>YOU</span>
            <div style={{ backgroundImage: `url("./assets/ui-battle-player-score.svg")` }}>
              <span>{roundData?.playerWins}</span>
            </div>
            <span
              style={{
                fontSize: '35px',
                marginBottom: '10px',
              }}
            >
              :
            </span>
            <div style={{ backgroundImage: `url("./assets/ui-battle-enemy-score.svg")` }}>
              <span>{roundData?.enemyWins}</span>
            </div>
            <span>OPPONENT</span>
          </div>
          <div className='battle-result-user-info' style={{ background: backgrounds[result] }}>
            <div style={{ flexDirection: 'column' }}>
              <span style={{ fontSize: '12px' }}>{userName}</span>
              <span style={{ fontSize: '20px' }}>{result}</span>
            </div>

            <div style={{ alignItems: 'center', gap: '8px' }}>
              {bet?.coinType && <img src={coinImages[bet?.coinType]} alt='coin-icon' />}
              <span style={{ fontSize: '32px' }}>
                {`${result === BattleResultEnum.LOSS ? '-' : '+'}${bet?.bet}`}
              </span>
            </div>
          </div>
          <button
            onClick={() => onFinish(bet?.coinType)}
            style={{ backgroundImage: 'url(./assets/ui_lobby-button.svg)' }}
          >
            <span>GO TO LOBBY</span>
          </button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className='pvp-fight-ui'>
      <div className='pvp-fight-header'>
        <PvpPlayerHealthBar
          className={cn(`health-bar`, {
            [healthBarClasses[roundData?.enemyWins!]]: true,
          })}
        />
        <div style={{ margin: '10px auto 0' }} className='round-box'>
          <Typography.Text
            style={{
              marginTop: '2px',
              color: '#FFFFFF',
              fontSize: '8px',
              textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
            }}
          >
            ROUND
          </Typography.Text>
          <Typography.Text
            style={{
              marginTop: '-7px',
              color: '#FFFFFF',
              fontSize: '24px',
              fontFamily: 'Kraft',
              textShadow: '1px 0 0 black, 0 1px 0 black, -1px 0 0 black, 0 -1px 0 black',
            }}
          >
            {pvpRound + 1}
          </Typography.Text>
        </div>
        <PvpEnemyHealthBar
          className={cn(`health-bar`, {
            [healthBarClasses[roundData?.playerWins!]]: true,
          })}
        />
      </div>
      {gameState === GameState.None && (
        <div className='round-sign-info user-sign'>{PvpSignIcons[signType]}</div>
      )}
      {gameState === GameState.None && (
        <div className='round-sign-info enemy-sign'>{PvpSignIcons[roundData?.enemySign!]}</div>
      )}
      <span className='round-result-text-info'>
        {gameState === GameState.None ? roundResult : phaseTimer}
      </span>
      {isPhaseStarted && (
        <div className='game-buttons'>
          {data.map((button, index) => {
            return (
              <React.Fragment key={index}>
                <button
                  style={
                    signType === PvpChoice.None
                      ? {}
                      : button.type === signType
                        ? {
                            background:
                              'linear-gradient(180deg, #0085DA 0%, #003290 100%), linear-gradient(180deg, #1D1D5F 0%, #050511 100%)',
                            boxShadow: '0px 0px 16px 0px rgba(25, 200, 250, 0.60)',
                          }
                        : {
                            opacity: 0.5,
                          }
                  }
                  disabled={gameState === GameState.None}
                  onClick={() => onSignChoice(button.type)}
                >
                  {button.content}
                </button>
                {index !== data.length - 1 && <span className='line' />}
              </React.Fragment>
            );
          })}
        </div>
      )}
      {battleWinner && renderBattleResult()}
    </div>
  );
};
