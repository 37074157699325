import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const BlueArrowSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='36' height='6' viewBox='0 0 36 6' fill='none'>
    <path d='M36 3L31 0.113249V5.88675L36 3ZM0 3.5H31.5V2.5H0V3.5Z' fill='#19C8FA' />
  </svg>
);

export const BlueArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BlueArrowSvg} {...props} />
);
