import type { ReactNode} from 'react';
import { useRef } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks/useStore';
import type { WebsocketMessage } from '../../../types/websocket';
import { WebsocketEventType } from '../../../types/websocket';

// Создание контекста
interface WebSocketContextType {
  ws: WebSocket | null;
  isConnected: boolean;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = observer(({ children }) => {
  const { tokenS: { isLoaded, isAuth, token } } = useStores();

  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const connectWebSocket = () => {
    if (!isAuth || !token) {
      console.log('Not authenticated, skipping WebSocket connection');
      return;
    }

    // Здесь мы добавляем токен в URL как параметр (или используйте другой метод передачи токена)
    const url = new URL(process.env.REACT_APP_API_WS_URL as string);
    url.searchParams.append('authorization', token); // Пример передачи токена через URL параметр

    const socket = new WebSocket(url.toString());

    socket.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
      setWs(socket);
    };

    socket.onmessage = (event) => {
      console.log('Received:', event.data);

      const msg: WebsocketMessage = JSON.parse(event.data);

      if (socket.readyState === WebSocket.OPEN && 'eventType' in msg) {
        switch (msg?.eventType) {
          case WebsocketEventType.ONLINE_ACTIVITY: {
            socket.send(JSON.stringify({ 'eventType': 'OK' }));
            break;
          }
        }
      }
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
      setIsConnected(false);
      attemptReconnect();
    };

    socket.onerror = (error) => {
      console.log('WebSocket error:', error);
    };
  };

  const attemptReconnect = () => {
    setTimeout(() => {
      console.log('WebSocket attempting to reconnect...');
      connectWebSocket();
    }, 5 * 1000); // Пытаемся переподключиться каждые 5 секунд
  };

  useEffect(() => {
    if (isLoaded && isAuth) {
      connectWebSocket();
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [isLoaded, isAuth, token]);

  useEffect(() => {
    if (isConnected && ws) {
      timerRef.current = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({"eventType":"OK"}));
        }
      }, 60 * 1000); // Отправка сообщения каждую минуту

      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [isConnected, ws]);

  return (
    <WebSocketContext.Provider value={ { ws, isConnected } }>
      { children }
    </WebSocketContext.Provider>
  );
});
