import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const ScissorsSvg = () => (
  <svg width='24' height='36' viewBox='0 0 24 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.49412 13.2668C6.49412 11.3587 8.07429 9.81189 10.0235 9.81189H11.5059L10.9283 3.16836C10.7833 1.50093 12.1268 0.0690918 13.8364 0.0690918C15.3229 0.0690918 16.5719 1.16273 16.7369 2.60884L17.8588 12.4376V4.04222C17.8588 2.38219 19.2336 1.03646 20.9294 1.03646C22.6252 1.03646 24 2.38219 24 4.04222V9.81189V24.1843C24 30.6717 18.6274 35.9309 12 35.9309C5.37258 35.9309 0.141174 30.6717 0.141174 24.1843V17.1363C0.141174 14.9992 1.91097 13.2668 4.09412 13.2668H6.49412Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.88235 11.1248H11.6412L12.2294 18.0345H7.76473V13.1876C7.77031 12.0474 8.71626 11.1248 9.88235 11.1248ZM6.35297 18.0387V13.1977H6.35294L6.35297 13.1846V13.1286H6.35363C6.39123 11.2524 7.9567 9.7428 9.88235 9.7428H11.5059L10.9283 3.09927C10.7833 1.43184 12.1268 0 13.8364 0C15.2915 0 16.519 1.04791 16.725 2.44855C16.7295 2.47879 16.7335 2.50919 16.737 2.53975L17.8588 10.0192V3.97313C17.8588 3.49928 17.9708 3.05104 18.1704 2.6524C18.6698 1.65448 19.7176 0.96737 20.9294 0.96737C22.6253 0.96737 24 2.31309 24 3.97313V24.2534C24 24.2597 24 24.266 24 24.2723V24.4607H23.9982C23.8852 30.8525 18.5567 36 12 36C5.37258 36 0 30.7408 0 24.2534V17.0672C0 14.9301 1.76979 13.1977 3.95294 13.1977H6.35294V14.5797H3.95294C2.54949 14.5797 1.41176 15.6934 1.41176 17.0672V24.2534C1.41176 29.9776 6.15228 34.618 12 34.618C17.8421 34.618 22.579 29.9866 22.5882 24.27V22.8714C22.5882 20.9633 21.0081 19.4165 19.0588 19.4165H6.5294C5.30138 19.4165 4.30587 20.391 4.30587 21.5931C4.30587 22.7952 5.30138 23.7697 6.5294 23.7697H9.67058C10.7621 23.7697 11.647 24.6359 11.647 25.7044V29.5739H10.2353V25.7044C10.2353 25.3991 9.98245 25.1516 9.67058 25.1516H6.5294C4.52168 25.1516 2.8941 23.5584 2.8941 21.5931C2.8941 19.6857 4.42719 18.1288 6.35297 18.0387ZM22.5882 19.4863V3.97313C22.5882 3.07633 21.8456 2.34933 20.9294 2.34933C20.0133 2.34933 19.2706 3.07633 19.2706 3.97313L18.5725 15.2322H17.0331L15.3339 2.69321C15.2487 1.94659 14.6038 1.38196 13.8364 1.38196C12.9538 1.38196 12.2601 2.1212 12.335 2.98208L12.9768 10.3647H12.9882L13.6412 18.0345H19.0588C20.4414 18.0345 21.6914 18.5904 22.5882 19.4863Z'
      fill='black'
    />
  </svg>
);

export const ScissorsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ScissorsSvg} {...props} />
);
